import { useEffect } from 'react';
// Event emitter allows you to communicate across tree components.
// This effect closes all menus when an item gets clicked anywhere
// in the tree.
const useCloseAllMenusOnItemClick = ({ setOpen, parentId, tree, refs }) => {
  useEffect(() => {
    function onTreeClick() {
      setOpen(false);

      if (parentId === null) {
        if (refs.reference.current) {
          refs.reference.current.focus();
        }
      }
    }
    if (tree) {
      tree.events.on('click', onTreeClick);
    }

    return () => {
      if (tree) {
        tree.events.off('click', onTreeClick);
      }
    };
  }, [setOpen, parentId, tree, refs]);
};

export default useCloseAllMenusOnItemClick;
