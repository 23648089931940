import React, { useState } from 'react';
import PropTypes from 'prop-types';
import traverseFragment from 'utils/traverseFragment';
import clsx from 'clsx';
import noop from 'noop';
import { useUID } from 'react-uid';
import Tab from './Tab';
import TabPanel from './TabPanel';
import InternalTab from './InternalTab';

const Tabs = ({
  children,
  className,
  activeKey: externalActiveKey,
  defaultActiveKey,
  id: customUid,
  onChange,
  activateOnFocus,
  renderAll,
  layout,
  ...otherProps
}) => {
  const generatedUid = useUID();
  const id = customUid || generatedUid;
  const [internalActiveKey, setInternalActiveKey] = useState(defaultActiveKey);
  const activeKey = externalActiveKey || internalActiveKey;

  const setActiveKey = (key) => {
    if (key !== activeKey) {
      onChange(key);
      if (internalActiveKey) {
        setInternalActiveKey(key);
      }
    }
  };

  const tabs = traverseFragment(children);
  const internalTabs = [];
  const internalTabPanels = [];

  React.Children.forEach(tabs, (child, index) => {
    const tabKey = child.key || String(index);
    const isActive = tabKey === activeKey;
    const { tabPanelProps, subHeading, iconStart, iconEnd, ...childProps } =
      child.props;
    internalTabs.push(
      <InternalTab
        {...childProps}
        key={tabKey}
        tabsId={id}
        tabKey={tabKey}
        setActiveKey={setActiveKey}
        activateOnFocus={activateOnFocus}
        isActive={isActive}
        subHeading={subHeading}
        layout={layout}
        iconStart={iconStart}
        iconEnd={iconEnd}
        className={className}
      />
    );
    internalTabPanels.push(
      <TabPanel
        {...tabPanelProps}
        key={tabKey}
        tabsId={id}
        tabKey={tabKey}
        isActive={isActive}
        renderAll={renderAll}
      >
        {childProps.children}
      </TabPanel>
    );
  });

  return (
    <div
      id={id}
      className={clsx('atls-tabs', className)}
      data-direction={layout}
      {...otherProps}
    >
      <div role='tablist' aria-orientation={layout}>
        {internalTabs}
      </div>
      {internalTabPanels}
    </div>
  );
};

Tabs.propTypes = {
  /**
   * Unique identifier for the component.
   */
  id: PropTypes.string,
  /**
   * Accepts Tabs.Tab components as children and renders them as tabs.
   */
  children: PropTypes.node.isRequired,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Default selected tab. Is not used in combination with `activeKey` prop.
   * If `activeKey` is set, this prop will be ignored.
   * The `Tabs` component will behave as an uncontrolled component.
   */
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Force a tab to be selected. The active key is always pointing to the current tab.
   * Is not used in combination with `defaultActiveKey`.
   * The `Tabs` component will behave as a controlled component.
   */
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Callback fired when a new tab is selected.
   */
  onChange: PropTypes.func,
  /**
   * `Tabs.Tab` is automatically activated and its associated panel is displayed when the tab receives focus.
   */
  activateOnFocus: PropTypes.bool,
  /**
   * Renders all tabpanels content. Regardless of the active tab.
   */
  renderAll: PropTypes.bool,
  /**
   * Sets layout of the tabs
   */
  layout: PropTypes.oneOf(['horizontal', 'vertical'])
};

Tabs.defaultProps = {
  id: null,
  className: null,
  defaultActiveKey: null,
  activeKey: null,
  onChange: noop,
  activateOnFocus: false,
  renderAll: true,
  layout: 'horizontal'
};

Tabs.Tab = Tab;

export default Tabs;
