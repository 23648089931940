import React, { useState } from 'react';
import { Toaster as OriginalToaster } from 'react-hot-toast';
import ToastBar from './ToastBar';
import ToastContext from './toastContext';

const Toaster = () => {
  const [paused, setPaused] = useState(false);
  return (
    <ToastContext.Provider value={{ paused, setPaused }}>
      <OriginalToaster>{(t) => <ToastBar toast={t} />}</OriginalToaster>
    </ToastContext.Provider>
  );
};

export default Toaster;
