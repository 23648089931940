import toast from 'react-hot-toast';
import merge from 'lodash.merge';
import getSuccessOptions from '../utils/getSuccessOptions';
import getErrorOptions from '../utils/getErrorOptions';
import getLoadingOptions from '../utils/getLoadingOptions';
import parseMessage from '../utils/parseMessage';

const promiseOptions = {
  loading: getLoadingOptions(),
  success: getSuccessOptions(),
  error: getErrorOptions()
};

const promise = (p, message, options) => {
  const messages = {
    loading: parseMessage(message.loading),
    success: parseMessage(message.success),
    error: parseMessage(message.error)
  };
  const mergedOptions = merge({}, promiseOptions, options);
  return toast.promise(p, messages, mergedOptions);
};

export default promise;
