import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { profileMenu } from './blocks';

const Divider = ({ className, ...otherProps }) => (
  <div
    className={clsx(profileMenu('divider'), className)}
    role='separator'
    {...otherProps}
  />
);

Divider.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string
};

Divider.defaultProps = {
  className: null
};

Divider.displayName = 'ProfileMenu.Divider';

export default Divider;
