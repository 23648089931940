import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { profileMenu } from './blocks';
import Header from './Header';
import Description from './Description';
import Main from './Main';
import Option from './Option';
import Footer from './Footer';
import SignOut from './SignOut';
import Divider from './Divider';

const ProfileMenu = ({ children, className, ...otherProps }) => (
  <div className={clsx(profileMenu(), className)} {...otherProps}>
    {children}
  </div>
);

ProfileMenu.propTypes = {
  /**
   * Children nodes of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string
};

ProfileMenu.defaultProps = {
  children: null,
  className: null
};

ProfileMenu.Header = Header;
ProfileMenu.Description = Description;
ProfileMenu.Main = Main;
ProfileMenu.Option = Option;
ProfileMenu.Divider = Divider;
ProfileMenu.Footer = Footer;
ProfileMenu.SignOut = SignOut;

export default ProfileMenu;
