import React from 'react';
import PropTypes from 'prop-types';

import {
  AuthorisedIcon,
  ErrorIcon,
  InformationV2Icon,
  WarningIcon,
  CloseV2Icon
} from 'components/icon';
import Spinner from 'components/spinner';

const ToastIcon = ({ appearance }) => {
  switch (appearance) {
    case 'success':
      return <AuthorisedIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'info':
      return <InformationV2Icon />;
    case 'error':
      return <ErrorIcon />;
    case 'loading':
      return <Spinner />;
    case 'close':
      return <CloseV2Icon />;
    default:
      return null;
  }
};

ToastIcon.propTypes = {
  appearance: PropTypes.string.isRequired
};

export default ToastIcon;
