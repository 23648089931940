import { isValidElementType } from 'react-is';

const getOverrideComponent = (override) => {
  if (isValidElementType(override)) {
    return override;
  }
  return override.component;
};

const defaultOverride = {};
const defaultProps = {};

const getOverrides = (defaultComponent, override = defaultOverride) => {
  const component = getOverrideComponent(override) || defaultComponent;
  const props = override.props || defaultProps;
  return [component, props];
};

export default getOverrides;
