import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ heading, content }) => (
  <div className='content'>
    <div className='heading'>{heading}</div>
    {content && <div>{content}</div>}
  </div>
);

Message.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.node
};

Message.defaultProps = {
  content: null
};

export default Message;
