import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Stack = ({ id, children, className, size, ...otherProps }) => (
  <div
    id={id}
    className={clsx('atls-bannerStack', className)}
    data-size={size}
    {...otherProps}
  >
    {children}
  </div>
);

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium'])
};

Stack.defaultProps = {
  id: null,
  className: null,
  size: null
};

Stack.displayName = 'Banner.Stack';

export default Stack;
