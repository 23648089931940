import React from 'react';
import { statusErrorBase } from '@atlas-design-system/tokens';
import mergeWithBaseOptions from './mergeWithBaseOptions';
import ToastIcon from '../ToastIcon';

const getErrorOptions = (options) => {
  const errorOptions = {
    style: {
      background: statusErrorBase
    },
    icon: <ToastIcon appearance='error' />,
    appearance: 'error'
  };
  const mergedOptions = mergeWithBaseOptions(errorOptions, options);
  return mergedOptions;
};

export default getErrorOptions;
