/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';

const SelectContainer = (props) => {
  const { innerProps, selectProps } = props;

  return (
    <components.SelectContainer
      {...props}
      innerProps={{
        ...innerProps,
        'data-testid': selectProps['data-testid']
      }}
    />
  );
};

export default SelectContainer;
