import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const DEFAULT_SIZE = '105px';
const VIEW_BOX = '0 0 105 105';

const IllustrationBase = React.forwardRef(
  ({ Illustration, className, width, ...restProps }, ref) => (
    <Illustration
      className={clsx(['atls-illustration', className])}
      {...restProps}
      ref={ref}
      viewBox={VIEW_BOX}
      width={width}
      height={width}
    />
  )
);

IllustrationBase.displayName = 'IllustrationBase';

IllustrationBase.propTypes = {
  Illustration: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})])
    .isRequired,
  className: PropTypes.string,
  width: PropTypes.string
};

IllustrationBase.defaultProps = {
  width: DEFAULT_SIZE,
  className: null
};

export default IllustrationBase;
