import React from 'react';
import ManualReverseSvg from '@atlas-design-system/core/dist/spot-illustrations/manual-reverse.svg';

import IllustrationBase from './IllustrationBase';

const ManualReverseIllustration = React.forwardRef((props, ref) => (
  <IllustrationBase {...props} ref={ref} Illustration={ManualReverseSvg} />
));

ManualReverseIllustration.displayName = 'ManualReverseIllustration';

export default ManualReverseIllustration;
