import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import FormHeader from './FormHeader';

const Form = ({
  id,
  className,
  formHeader,
  formSubtitle,
  onSubmit,
  children,
  ...otherProps
}) => (
  <form
    className={clsx(['atls-form', className])}
    onSubmit={onSubmit}
    {...otherProps}
  >
    {(formHeader || formSubtitle) && (
      <FormHeader id={id} header={formHeader} subtitle={formSubtitle} />
    )}
    {children}
  </form>
);

Form.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  formHeader: PropTypes.string,
  formSubtitle: PropTypes.string,
  onSubmit: PropTypes.func
};

Form.defaultProps = {
  /**
   * Custom id.
   */
  id: null,
  /**
   * Custom class.
   */
  className: null,
  /**
   * Adds text or children.
   */
  children: PropTypes.node,
  /**
   * Large header text.
   */
  formHeader: null,
  /**
   * Medium subtitle text.
   */
  formSubtitle: null,
  /**
   * Callback to be fired when submitting form.
   */
  onSubmit: null
};

export default Form;
