import React, { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { Select } from 'components/select';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/icon';
import Button from 'components/button';
import { pagination } from './blocks';

const Pagination = ({
  className,
  pageSizeOptions,
  defaultPageSize,
  pageIndex,
  dataSize,
  canNextPage,
  canPreviousPage,
  menuPlacement,
  nextPage,
  previousPage,
  pageSize,
  onShowRowsChange
}) => {
  const options = pageSizeOptions.filter((option) => option.value <= dataSize);

  if (options.length === 0) {
    options.push({ label: dataSize.toString(), value: dataSize });
  }

  const showRows = () => {
    let endRange = (pageIndex + 1) * pageSize;
    const startRange = endRange - (pageSize - 1);

    endRange = endRange > dataSize ? dataSize : endRange;

    return `${startRange} - ${endRange} of ${dataSize}`;
  };

  const getDefaultedSelectedItem = () =>
    options.find((el) => el.value === defaultPageSize) || options[0];

  const firstRender = useRef(true);
  useEffectOnce(() => {
    if (firstRender.current) {
      onShowRowsChange(getDefaultedSelectedItem().value);
      firstRender.current = false;
    }
  });

  const onSelectShowRowsChange = (e) => onShowRowsChange(e.value);

  return (
    <div className={clsx(pagination(), className)} aria-label='Pagination'>
      <div className={pagination('dropdown')}>
        <span>Show rows</span>
        <Select
          next
          options={options}
          inputId='pagination-rows'
          className={pagination('button')}
          menuPlacement={menuPlacement}
          defaultValue={getDefaultedSelectedItem()}
          onChange={onSelectShowRowsChange}
        />
      </div>
      <label className={pagination('rows-range')}>{showRows()}</label>
      <>
        <Button
          appearance='secondary'
          icon={<ArrowLeftIcon />}
          aria-label='Left'
          disabled={!canPreviousPage}
          onClick={previousPage}
          className={pagination('button')}
        />
        <Button
          appearance='secondary'
          icon={<ArrowRightIcon />}
          aria-label='Right'
          disabled={!canNextPage}
          onClick={nextPage}
          className={pagination('button')}
        />
      </>
    </div>
  );
};

Pagination.propTypes = {
  /**
   * Adds a class or classes.
   */
  className: PropTypes.string,
  /**
   * Defines the page size options
   */
  pageSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
  ).isRequired,
  /**
   * Sets the current page size
   */
  pageSize: PropTypes.number.isRequired,
  /**
   * Sets the data size
   */
  dataSize: PropTypes.number.isRequired,
  /**
   * Sets the current page index
   */
  pageIndex: PropTypes.number.isRequired,
  /**
   * Sets true or false if table can go next page
   */
  canNextPage: PropTypes.bool.isRequired,
  /**
   * Sets true or false if table can go previous page
   */
  canPreviousPage: PropTypes.bool.isRequired,
  /**
   * The preferred placement of the rows selection menu
   */
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  /**
   * Fires next page function
   */
  nextPage: PropTypes.func.isRequired,
  /**
   * Fires previous page function
   */
  previousPage: PropTypes.func.isRequired,
  /**
   * Sets the default page size
   */
  defaultPageSize: PropTypes.number,
  /**
   * Fires show rows onChange function
   */
  onShowRowsChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  className: null,
  defaultPageSize: null,
  menuPlacement: 'bottom'
};

export default Pagination;
