export default function getFlipModifier(options = {}) {
  const {
    fallbackPlacements = ['bottom', 'auto'],
    altBoundary = true,
    disableFlip
  } = options;

  const flipModifier = {
    name: 'flip'
  };

  if (disableFlip) {
    return flipModifier;
  }

  flipModifier.options = {
    fallbackPlacements,
    altBoundary
  };

  return flipModifier;
}
