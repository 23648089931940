// Custom Proptypes valitation for HTML elements

export default function HTMLElementType(
  props,
  propName,
  componentName,
  location,
  propFullName
) {
  // eslint-disable-next-line react/destructuring-assignment
  const propValue = props[propName];
  const safePropName = propFullName || propName;

  if (propValue == null) {
    return null;
  }

  if (propValue && propValue.nodeType !== 1) {
    return new Error(
      `Invalid ${location} \`${safePropName}\` supplied to \`${componentName}\`. ` +
        `Expected an HTMLElement.`
    );
  }

  return null;
}
