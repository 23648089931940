import React from 'react';
import AuthorisedSvg from '@atlas-design-system/core/dist/svg/authorised.svg';

import IconBase from './IconBase';

const AuthorisedIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={AuthorisedSvg} />
));

AuthorisedIcon.displayName = 'AuthorisedIcon';

export default AuthorisedIcon;
