import React from 'react';
import PropTypes from 'prop-types';
import { CloseV2Icon } from 'components/icon';

const Header = ({ titleId, children, onDismiss }) => (
  <header>
    <h1 id={titleId}>{children}</h1>
    <div className='close'>
      <button type='button' title='Close' onClick={onDismiss}>
        <CloseV2Icon />
      </button>
    </div>
  </header>
);

Header.propTypes = {
  titleId: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default Header;
