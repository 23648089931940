/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { components } from 'react-select';

const MenuList = (props) => {
  const menuListRef = React.useRef(null);
  const { cx, innerRef } = props;

  const setRef = useCallback(
    (ref) => {
      menuListRef.current = ref;
      innerRef(ref);
    },
    [innerRef]
  );

  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop } = e.target;
      const scrollingClass = cx({ 'menu-list--is-scrolling': true });

      if (scrollTop > 0) {
        e.target.classList.add(scrollingClass);
      } else {
        e.target.classList.remove(scrollingClass);
      }
    };
    const element = menuListRef.current;

    element.addEventListener('scroll', handleScroll, { passive: true });

    return () => element.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <components.MenuList {...props} innerRef={setRef} />;
};

export default MenuList;
