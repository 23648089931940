import { useEffect } from 'react';
import onEnter from 'utils/onEnter';
import onArrowDown from 'utils/onArrowDown';
import onArrowUp from 'utils/onArrowUp';
import onTab from 'utils/onTab';
import onEsc from 'utils/onEsc';
import onHome from 'utils/onHome';
import onEnd from 'utils/onEnd';

const useListNavigation = (menuRef) => {
  let currentFocusedItemIndex;

  const onEnterHandler = (_event, nodes) => {
    currentFocusedItemIndex = 0;
    nodes[currentFocusedItemIndex].focus();
  };

  const onTabHandler = () => {
    currentFocusedItemIndex = null;
  };

  const onEscapeHandler = () => {
    currentFocusedItemIndex = null;
    menuRef.current.focus();
  };

  const onHomeHandler = (_event, nodes) => {
    currentFocusedItemIndex = 0;
    nodes[currentFocusedItemIndex].focus();
  };

  const onEndHandler = (_event, nodes) => {
    currentFocusedItemIndex = nodes.length - 1;
    nodes[currentFocusedItemIndex].focus();
  };

  const onArrowDownHandler = (event, nodes) => {
    event.preventDefault();
    if (currentFocusedItemIndex < nodes.length - 1) {
      do {
        currentFocusedItemIndex += 1;
      } while (nodes[currentFocusedItemIndex].role !== 'menuitem');
    } else if (currentFocusedItemIndex === nodes.length - 1) {
      currentFocusedItemIndex = 0;
    }
    nodes[currentFocusedItemIndex].focus();
  };

  const onArrowUpHandler = (event, nodes) => {
    event.preventDefault();
    if (currentFocusedItemIndex > 0) {
      do {
        currentFocusedItemIndex -= 1;
      } while (nodes[currentFocusedItemIndex].role !== 'menuitem');
    } else if (currentFocusedItemIndex === 0) {
      currentFocusedItemIndex = nodes.length - 1;
    }
    nodes[currentFocusedItemIndex].focus();
  };

  const keysHandler = (event) => {
    const nodeChildren = menuRef.current?.children || [];
    if (!currentFocusedItemIndex) {
      onEnter(onEnterHandler)(event, nodeChildren);
    }
    if (typeof currentFocusedItemIndex === 'number') {
      onArrowDown(onArrowDownHandler)(event, nodeChildren);
      onArrowUp(onArrowUpHandler)(event, nodeChildren);
      onTab(onTabHandler)(event);
      onEsc(onEscapeHandler)(event);
      onHome(onHomeHandler)(event, nodeChildren);
      onEnd(onEndHandler)(event, nodeChildren);
    }
  };

  const clickHandler = () => {
    currentFocusedItemIndex = null;
  };

  useEffect(() => {
    const menuRefCurrent = menuRef.current;
    if (menuRefCurrent) {
      menuRefCurrent.addEventListener('keydown', keysHandler);
    }
    document.addEventListener('click', clickHandler);
    return () => {
      if (menuRefCurrent) {
        menuRefCurrent.removeEventListener('keydown', keysHandler);
      }
      document.removeEventListener('click', clickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef]);
};

export default useListNavigation;
