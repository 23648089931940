import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import HTMLElementType from 'utils/HTMLElementType';

const getContainer = (container) =>
  typeof container === 'function' ? container() : container;

const Portal = ({ children, container, disabled }) => {
  if (disabled || !container) {
    return children;
  }

  return createPortal(children, getContainer(container));
};

Portal.propTypes = {
  /**
   * The content to be rendered inside the portal.
   */
  children: PropTypes.node.isRequired,
  /**
   * The element to append the children to.
   */
  appendTo: PropTypes.oneOfType([PropTypes.func, HTMLElementType]),
  /**
   * Render the children withouth the portal.
   */
  disabled: PropTypes.bool
};

export default Portal;
