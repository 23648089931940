import React from 'react';
import { statusSuccessBase } from '@atlas-design-system/tokens';
import mergeWithBaseOptions from './mergeWithBaseOptions';
import ToastIcon from '../ToastIcon';

const getSuccessOptions = (options) => {
  const successOptions = {
    style: {
      background: statusSuccessBase
    },
    icon: <ToastIcon appearance='success' />,
    appearance: 'success'
  };

  const mergedOptions = mergeWithBaseOptions(successOptions, options);
  return mergedOptions;
};

export default getSuccessOptions;
