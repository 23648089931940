import React, { forwardRef } from 'react';
import {
  useFloatingParentNodeId,
  FloatingTree
} from '@floating-ui/react-dom-interactions';
import PropTypes from 'prop-types';
import Item from './Item';
import Menu from './Menu';
import DropdownMenuContext from './dropdownMenuContext';

const DropdownMenu = forwardRef(
  ({ onClick, onSubMenuItemClick, onToggle, ...props }, ref) => {
    const parentId = useFloatingParentNodeId();

    if (parentId == null) {
      return (
        <DropdownMenuContext.Provider
          value={{ onItemSelection: onClick, onToggle }}
        >
          <FloatingTree>
            <Menu
              onSubMenuItemClick={onSubMenuItemClick}
              {...props}
              ref={ref}
            />
          </FloatingTree>
        </DropdownMenuContext.Provider>
      );
    }
    return (
      <Menu onSubMenuItemClick={onSubMenuItemClick} {...props} ref={ref} />
    );
  }
);

DropdownMenu.displayName = 'DropdownMenu';

DropdownMenu.Item = Item;

DropdownMenu.propTypes = {
  /**
   * Callback function triggered when Item is selected.
   */
  onClick: PropTypes.func,
  /**
   * Callback on item click.
   */
  onSubMenuItemClick: PropTypes.func,
  /**
   * Callback on menu open or close.
   */
  onToggle: PropTypes.func
};

DropdownMenu.defaultProps = {
  onClick: null,
  onSubMenuItemClick: null,
  onToggle: null
};

export default DropdownMenu;
