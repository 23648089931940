import React from 'react';
import InformationV2Svg from '@atlas-design-system/core/dist/svg/information-v2.svg';

import IconBase from './IconBase';

const InformationV2Icon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={InformationV2Svg} />
));

InformationV2Icon.displayName = 'InformationV2Icon';

export default InformationV2Icon;
