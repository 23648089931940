import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const FooterMenu = ({ className, title, children, ...otherProps }) => (
  <div className={clsx(['footerLinkList', className])} {...otherProps}>
    {title && <h1>{title}</h1>}
    {children && (
      <ul>
        {children &&
          React.Children.map(children, (child) => (
            <li>{React.cloneElement(child, { ...child.props })}</li>
          ))}
      </ul>
    )}
  </div>
);

FooterMenu.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

FooterMenu.defaultProps = {
  /**
   * Custom class.
   */
  className: null,
  /**
   * H1 menu header title
   */
  title: null
};

FooterMenu.displayName = 'Footer.Menu';

export default FooterMenu;
