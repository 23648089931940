import React from 'react';
import NoResultsReverseSvg from '@atlas-design-system/core/dist/spot-illustrations/no-results-reverse.svg';

import IllustrationBase from './IllustrationBase';

const NoResultsReverseIllustration = React.forwardRef((props, ref) => (
  <IllustrationBase {...props} ref={ref} Illustration={NoResultsReverseSvg} />
));

NoResultsReverseIllustration.displayName = 'NoResultsReverseIllustration';

export default NoResultsReverseIllustration;
