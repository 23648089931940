import React, { useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const OverflowContent = ({
  id,
  className,
  size,
  children,
  lines,
  showLessText,
  showMoreText,
  'data-testid': dataTestId
}) => {
  const [shouldTruncate, toggleTruncate] = useState(true);

  const readMoreEllipsis = (
    <>
      {shouldTruncate ? <span>&#8230;&nbsp;</span> : ' '}
      <button
        className='toggleBtn'
        type='button'
        onClick={() =>
          toggleTruncate((prevShouldTruncate) => !prevShouldTruncate)
        }
      >
        {!shouldTruncate ? showLessText : showMoreText}
      </button>
    </>
  );
  return (
    <div
      id={id}
      className={clsx(['overflowContent', className])}
      data-size={size}
      data-testid={dataTestId}
    >
      {shouldTruncate ? (
        <TruncateMarkup
          lines={lines}
          ellipsis={readMoreEllipsis}
          tokenize='words'
        >
          <div className='outerWrapper'>{children}</div>
        </TruncateMarkup>
      ) : (
        <div className='outerWrapper'>
          <>
            {children}
            {readMoreEllipsis}
          </>
        </div>
      )}
    </div>
  );
};

OverflowContent.propTypes = {
  /**
   * Adds an ID.
   */
  id: PropTypes.string,
  /**
   * Adds a class or classes.
   */
  className: PropTypes.string,
  /**
   * Content to be displayed.
   */
  children: PropTypes.node,
  /**
   * The size of the text.
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * The number of lines to be displayed.
   */
  lines: PropTypes.number,
  /**
   * The text to show when the content is truncated.
   */
  showMoreText: PropTypes.string,
  /**
   * The text to show when the content is not truncated.
   */
  showLessText: PropTypes.string,
  'data-testid': PropTypes.string
};

OverflowContent.defaultProps = {
  id: null,
  className: null,
  children: null,
  size: 'medium',
  lines: 1,
  showMoreText: 'show more',
  showLessText: 'show less',
  'data-testid': null
};

OverflowContent.displayName = 'Banner.OverflowContent';

export default OverflowContent;
