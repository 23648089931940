/* eslint-disable react/prop-types */
import React from 'react';

const DropdownIndicator = (props) => {
  const { cx, selectProps } = props;
  const { menuIsOpen } = selectProps;

  return (
    <div
      className={cx({
        'dropdown-indicator': true
      })}
    >
      <svg
        viewBox='0 0 12 8'
        role='presentation'
        xmlns='http://www.w3.org/2000/svg'
        className={cx({
          'down-chevron': true,
          'down-chevron--menu-is-open': menuIsOpen
        })}
      >
        <path d='M1.66896 0.756706C1.25845 0.387247 0.626165 0.420525 0.256706 0.831035C-0.112753 1.24155 -0.0794748 1.87384 0.331035 2.24329L1.66896 0.756706ZM6 6L5.33104 6.74329L6 7.34536L6.66896 6.74329L6 6ZM11.669 2.24329C12.0795 1.87384 12.1128 1.24155 11.7433 0.831035C11.3738 0.420525 10.7415 0.387247 10.331 0.756706L11.669 2.24329ZM0.331035 2.24329L5.33104 6.74329L6.66896 5.25671L1.66896 0.756706L0.331035 2.24329ZM6.66896 6.74329L11.669 2.24329L10.331 0.756706L5.33104 5.25671L6.66896 6.74329Z' />
      </svg>
    </div>
  );
};

export default DropdownIndicator;
