import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import noop from 'noop';
import { useKey, useLockBodyScroll, useUpdateEffect } from 'react-use';
import useTrapFocus from 'hooks/useTrapFocus';
import useFocusLastOutSideActiveElementEffect from 'hooks/useFocusLastOutSideActiveElementEffect';
import Portal from 'components/portal';
import Button from 'components/button';
import ButtonGroup from 'components/button-group';
import HTMLElementType from 'utils/HTMLElementType';
import { dialog } from './blocks';
import DialogIcon from './DialogIcon';

const Dialog = ({
  id,
  className,
  hidden,
  title,
  buttons,
  children,
  appearance,
  afterClose,
  focusTriggerAfterClose,
  onDismiss,
  dismissText,
  onConfirm,
  confirmText,
  appendTo,
  dialogProps,
  ...otherProps
}) => {
  const dialogRef = useRef();
  useTrapFocus(dialogRef, !hidden);
  useLockBodyScroll(!hidden);
  useKey((event) => event.key === 'Escape' && !hidden, onDismiss);
  useFocusLastOutSideActiveElementEffect(
    dialogRef,
    !hidden && focusTriggerAfterClose
  );

  const getFocusableElements = () => {
    const focusableElements = dialogRef.current.querySelectorAll(
      "button, input, a, [tabindex='0']"
    );

    return [
      focusableElements[0],
      focusableElements[focusableElements.length - 1],
      focusableElements
    ];
  };

  const handleAfterClose = () => {
    if (
      focusTriggerAfterClose &&
      useFocusLastOutSideActiveElementEffect.current
    ) {
      useFocusLastOutSideActiveElementEffect.current.focus();
    }
    afterClose();
  };

  const handleAfterOpen = () => {
    const [firstFocusableButton] = getFocusableElements();
    if (firstFocusableButton) {
      firstFocusableButton.focus();
    }
  };

  useUpdateEffect(() => {
    if (
      !hidden &&
      focusTriggerAfterClose &&
      !dialogRef.current?.contains(document.activeElement)
    ) {
      useFocusLastOutSideActiveElementEffect.current = document.activeElement;
    }
    !hidden ? handleAfterOpen() : handleAfterClose();
  }, [hidden, focusTriggerAfterClose]);

  const renderActionButtons = () => {
    if (buttons) {
      return buttons;
    }

    return (
      <>
        {onConfirm && (
          <Button appearance='primary' onClick={onConfirm}>
            {confirmText}
          </Button>
        )}
        <Button
          appearance={onConfirm ? 'secondary' : 'primary'}
          onClick={onDismiss}
        >
          {dismissText}
        </Button>
      </>
    );
  };

  return hidden ? null : (
    <Portal container={appendTo}>
      <div
        className={clsx('atls-dialog', className)}
        hidden={hidden}
        {...otherProps}
      >
        <div
          ref={dialogRef}
          id={id}
          className='innerDialog'
          role='alertdialog'
          aria-modal='true'
          aria-labelledby={title && `${id}Label`}
          aria-describedby={`${id}Desc`}
          data-appearance={appearance}
          aria-label={appearance === 'neutral' ? '' : appearance}
          {...dialogProps}
        >
          <div className='content'>
            <div className='icon'>
              <DialogIcon appearance={appearance} />
            </div>
            <div
              className={dialog('text', {
                'no-icon': appearance === 'neutral'
              })}
            >
              {title && (
                <h2 id={`${id}Label`} className='dialogLabel'>
                  {title}
                </h2>
              )}
              <div id={`${id}Desc`} className='dialogDesc'>
                {children}
              </div>
            </div>
          </div>
          <div className='dialogActions'>
            <ButtonGroup align='right'>{renderActionButtons()}</ButtonGroup>
          </div>
        </div>
      </div>
    </Portal>
  );
};

Dialog.propTypes = {
  /**
   * The id of the dialog.
   * It will be used to set the `aria-labelledby` and `aria-describedby` attribute on the dialog.
   */
  id: PropTypes.string.isRequired,
  /**
   * Sets a custom class to the dialog.
   */
  className: PropTypes.string,
  /**
   * The title of the dialog.
   */
  title: PropTypes.string,
  /**
   * The dialog content.
   */
  children: PropTypes.node.isRequired,
  /**
   * If `true` the dialog will be hidden.
   */
  hidden: PropTypes.bool,
  /**
   * Callback fired when the user click on the dismiss button or press the ESC key.
   */
  onDismiss: PropTypes.func.isRequired,
  /**
   * Override the text of the dismiss button.
   */
  dismissText: PropTypes.string,
  /**
   * Callback fired when the user click on the confirm button.
   */
  onConfirm: PropTypes.func,
  /**
   * Override the text of the confirm button
   */
  confirmText: PropTypes.string,
  /**
   * The apperance of the dialog.
   */
  appearance: PropTypes.oneOf([
    'success',
    'warning',
    'information',
    'error',
    'neutral'
  ]),
  /**
   * Callback fired after the dialog has been closed.
   */
  afterClose: PropTypes.func,
  /**
   * Overwrite the default action buttons.
   */
  buttons: PropTypes.node,
  /**
   * If `true` the trigger will be focused after the dialog has been closed.
   */
  focusTriggerAfterClose: PropTypes.bool,
  /**
   * The element to append the `Dialog` to.
   * Sometimes the `Dialog` needs to be appended to a different DOM context due to accessibility or z-index issues.
   */
  appendTo: PropTypes.oneOfType([PropTypes.func, HTMLElementType]),
  /**
   * Adds custom HTML attribute to the dialog
   */
  dialogProps: PropTypes.shape({})
};

Dialog.defaultProps = {
  afterClose: noop,
  appearance: 'neutral',
  buttons: null,
  className: null,
  confirmText: 'Confirm',
  dismissText: 'Dismiss',
  focusTriggerAfterClose: false,
  hidden: true,
  onConfirm: null,
  title: null,
  appendTo: null,
  dialogProps: {}
};

export default Dialog;
