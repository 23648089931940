import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const LogoBase = React.forwardRef(({ Logo, className, ...restProps }, ref) => (
  <Logo className={clsx(['atls-logo', className])} {...restProps} ref={ref} />
));

LogoBase.displayName = 'LogoBase';

LogoBase.propTypes = {
  Logo: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
  className: PropTypes.string
};

LogoBase.defaultProps = {
  className: null
};

export default LogoBase;
