import React, { useState } from 'react';
import { spaceXS, animationFast } from '@atlas-design-system/tokens';
import PropTypes from 'prop-types';
import HTMLElementType from 'utils/HTMLElementType';
import Tippy from '@tippyjs/react/headless';
import clsx from 'clsx';
import hideOnEsc from 'utils/tippy/plugins/hideOnEsc';
import getArrowModifier from 'utils/tippy/modifiers/getArrowModifier';
import getOffsetModifier from 'utils/tippy/modifiers/getOffsetModifier';
import getFlipModifier from 'utils/tippy/modifiers/getFlipModifier';

// Same values in tooltip.pcss
const triangleHeight = {
  medium: 8,
  small: 4
};

const animationFastProp = parseInt(animationFast, 10);

export const tooltipPlacements = [
  'auto',
  'auto-start',
  'auto-end',
  'top',
  'top-start',
  'top-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'right',
  'right-start',
  'right-end',
  'left',
  'left-start',
  'left-end'
];

const Tooltip = ({
  id,
  children,
  className,
  content,
  delayHide,
  delayShow,
  disabled,
  interactive,
  onClickOutside,
  placement,
  reference,
  showOnCreate,
  size,
  visible,
  appendTo,
  disableFlip,
  ...otherProps
}) => {
  const [arrow, setArrow] = useState(null);
  return (
    <Tippy
      visible={visible}
      disabled={disabled}
      onClickOutside={onClickOutside}
      interactive={interactive}
      showOnCreate={showOnCreate}
      delay={[delayShow, delayHide]}
      placement={placement}
      reference={reference}
      appendTo={appendTo}
      aria={{
        content: 'labelledby'
      }}
      plugins={[hideOnEsc]}
      render={(attrs) => (
        <div
          id={id}
          role='tooltip'
          data-placement={placement}
          data-size={size}
          className={clsx(['atls-tooltip', className])}
          tabIndex='-1'
          {...otherProps}
          {...attrs}
        >
          <div className='tooltipContent'>{content}</div>
          <div ref={setArrow} className='tooltipArrow' />
        </div>
      )}
      popperOptions={{
        modifiers: [
          getArrowModifier(arrow),
          getOffsetModifier([0, triangleHeight[size] + parseInt(spaceXS, 10)]),
          getFlipModifier({ disableFlip })
        ]
      }}
    >
      {children}
    </Tippy>
  );
};

Tooltip.propTypes = {
  /**
   * The id of the tooltip.
   */
  id: PropTypes.string,
  /**
   * The component to display the tooltip.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * The content of the tooltip
   */
  content: PropTypes.node.isRequired,
  /**
   * Delay in ms once a trigger event is fired before a tooltip shows.
   */
  delayShow: PropTypes.number,
  /**
   * Delay in ms once a trigger event is fired before a tooltip hides.
   */
  delayHide: PropTypes.number,
  /**
   * Disable the tooltip.
   */
  disabled: PropTypes.bool,
  /**
   * Determines if the tooltip has interactive content inside of it,
   * so that it can be hovered over and clicked inside without hiding.
   */
  interactive: PropTypes.bool,
  /**
   * Invoked when the user clicks anywhere outside of the tooltip or reference element.
   */
  onClickOutside: PropTypes.func,
  /**
   * The preferred placement of the tooltip.
   */
  placement: PropTypes.oneOf(tooltipPlacements),
  /**
   * If you can't place your reference element as a child inside `<Tooltip />`, you can use this prop instead. It accepts a React Reference.
   */
  reference: PropTypes.oneOfType([
    PropTypes.func,
    HTMLElementType,
    PropTypes.shape({
      current:
        typeof Element === 'undefined'
          ? PropTypes.any
          : PropTypes.instanceOf(Element)
    })
  ]),
  /**
   * Determines if the tooltip is shown once it gets created, respecting the delay.
   */
  showOnCreate: PropTypes.bool,
  /**
   * The size of the tooltip.
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Use React's state to fully control the tippy instead of relying on the native trigger and hideOnClick props.
   */
  visible: PropTypes.bool,
  /**
   * The element to append the `Tooltip` to.
   * Sometimes the `Tooltip` needs to be appended to a different DOM context due to accessibility or z-index issues.
   */
  appendTo: PropTypes.oneOfType([PropTypes.func, HTMLElementType]),
  /**
   * Disable the flip modifier.
   * This is useful when the tooltip is used inside a scrollable container.
   * The tooltip will be positioned outside of the scrollable container.
   * The flip modifier will try to flip the tooltip to the other side of the reference element.
   */
  disableFlip: PropTypes.bool
};

const appendTo = () => document.body;

Tooltip.defaultProps = {
  id: null,
  className: null,
  children: null,
  size: 'medium',
  placement: 'top',
  disabled: false,
  interactive: true,
  showOnCreate: false,
  visible: undefined,
  reference: null,
  delayShow: animationFastProp,
  delayHide: 0,
  appendTo,
  onClickOutside: undefined,
  disableFlip: false
};

export default Tooltip;
