import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { profileMenu } from './blocks';

const Footer = ({ children, className, ...otherProps }) => (
  <div className={clsx(profileMenu('footer'), className)} {...otherProps}>
    {children}
  </div>
);

Footer.propTypes = {
  /**
   * Children nodes of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string
};

Footer.defaultProps = {
  children: null,
  className: null
};

Footer.displayName = 'ProfileMenu.Footer';

export default Footer;
