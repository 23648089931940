import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import noop from 'noop';
import { ShowIcon, HideIcon } from 'components/icon';

const getIconClassName = (position, isDisabled) => {
  let classname = '';
  if (position === 'left') {
    classname = 'leadingIcon';
  } else if (position === 'right') {
    classname = 'trailingIcon';
  }
  if (isDisabled) {
    classname = `${classname} disabled`;
  }
  return classname;
};

const Input = React.forwardRef(
  (
    {
      id,
      className,
      type,
      name,
      defaultValue,
      disabled,
      readOnly,
      required,
      placeholder,
      autocomplete,
      onChange,
      onBlur,
      iconLeft,
      iconRight,
      min,
      max,
      step,
      maxlength,
      wrapperProps,
      ...otherProps
    },
    ref
  ) => {
    const inputRef = useRef();
    const [internalType, setType] = useState(type);
    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
      setType(type);
    }, [type]);

    const isPasswordType = internalType === 'password';

    const togglePassword = () =>
      isPasswordType ? setType('text') : setType('password');

    const handleMouseUp = () => {
      inputRef.current.focus();
    };

    return (
      <div
        className={clsx([
          'atls-input',
          type === 'password' && 'password',
          className
        ])}
        onMouseUp={handleMouseUp}
        role='none'
        {...wrapperProps}
      >
        {iconLeft &&
          React.cloneElement(iconLeft, {
            className: getIconClassName('left', disabled)
          })}
        {iconRight &&
          React.cloneElement(iconRight, {
            className: getIconClassName('right', disabled)
          })}
        <input
          ref={inputRef}
          id={id}
          type={internalType}
          name={name}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          placeholder={placeholder}
          autoComplete={autocomplete}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxlength}
          {...otherProps}
        />
        {type === 'password' && (
          <button
            type='button'
            aria-label='Show or hide password'
            onClick={togglePassword}
            className='togglePassword'
          >
            <HideIcon className='hidePassword' />
            <ShowIcon className='showPassword' />
          </button>
        )}
      </div>
    );
  }
);

Input.propTypes = {
  /**
   * Adds an ID.
   */
  id: PropTypes.string,
  /**
   * Adds a class or classes.
   */
  className: PropTypes.string,
  /**
   * Defines the input type.
   */
  type: PropTypes.oneOf([
    'text',
    'search',
    'password',
    'email',
    'number',
    'tel',
    'time',
    'url'
  ]),
  /**
   * Sets a name (a title for the inputs value).
   */
  name: PropTypes.string,
  /**
   * Sets an default starting value.
   */
  defaultValue: PropTypes.string,
  /**
   * Sets as disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Sets as readonly.
   */
  readOnly: PropTypes.bool,
  /**
   * Sets as required.
   */
  required: PropTypes.bool,
  /**
   * Adds placeholder text.
   */
  placeholder: PropTypes.string,
  /**
   * Change callback event
   */
  onChange: PropTypes.func,
  /**
   * Blur callback event
   */
  onBlur: PropTypes.func,
  /**
   * [HTML autocomplete attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
   */
  autocomplete: PropTypes.string,
  /**
   * Adds icon to the left.
   */
  iconLeft: PropTypes.node,
  /**
   * Adds icon to the right.
   */
  iconRight: PropTypes.node,
  /**
   * [HTML min attribute](https://www.w3schools.com/tags/att_min.asp)
   */
  min: PropTypes.string,
  /**
   * [HTML max attribute](https://www.w3schools.com/tags/att_max.asp)
   */
  max: PropTypes.string,
  /**
   * [HTML step attribute](https://www.w3schools.com/tags/att_step.asp)
   */
  step: PropTypes.string,
  /**
   * [HTML maxlength attribute](https://www.w3schools.com/tags/att_input_maxlength.asp)
   */
  maxlength: PropTypes.number,
  /**
   * Adds custom HTML attribute to input
   */
  wrapperProps: PropTypes.shape({})
};

Input.defaultProps = {
  id: null,
  name: null,
  className: null,
  type: 'text',
  defaultValue: undefined,
  disabled: false,
  readOnly: false,
  required: false,
  placeholder: null,
  autocomplete: null,
  onChange: noop,
  onBlur: noop,
  iconLeft: null,
  iconRight: null,
  min: null,
  max: null,
  step: null,
  maxlength: null,
  wrapperProps: {}
};

Input.displayName = 'Input';

export default Input;
