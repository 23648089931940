import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Item = forwardRef(
  (
    { label, disabled, children, renderLabel, href, asLink, ...otherProps },
    ref
  ) => {
    const ItemContent = renderLabel
      ? renderLabel({ label, disabled, children, ...otherProps })
      : children;

    if (href || asLink) {
      const Component = asLink || 'a';
      return (
        <Component
          href={href}
          ref={ref}
          role='menuitem'
          disabled={disabled}
          {...otherProps}
        >
          {ItemContent}
        </Component>
      );
    }
    return (
      <button
        type='button'
        ref={ref}
        role='menuitem'
        disabled={disabled}
        {...otherProps}
      >
        {ItemContent}
      </button>
    );
  }
);

Item.propTypes = {
  /**
   * Children nodes of the component.
   */
  children: PropTypes.node,
  /**
   * Text for the button.
   */
  label: PropTypes.string,
  /**
   * Custom classname applied.
   */
  disabled: PropTypes.bool,
  /**
   * The presenrce of the href attribute will render an anchor element instead of the default button element.
   */
  href: PropTypes.string,
  /**
   * Render function to manipulate the label displayed
   */
  renderLabel: PropTypes.func,
  /**
   * Use this prop if you want to use a custom component.
   * For example, you can use it with `react-router` to render a `<Link>` component.
   */
  asLink: PropTypes.elementType
};

Item.defaultProps = {
  children: null,
  label: null,
  disabled: null,
  href: null,
  renderLabel: null,
  asLink: null
};

Item.displayName = 'DropdownMenu.Item';

export default Item;
