import Toaster from './Toaster';
import success from './methods/success';
import error from './methods/error';
import promise from './methods/promise';
import remove from './methods/remove';
import warning from './methods/warning';
import info from './methods/info';
import dismiss from './methods/dismiss';

const toast = {
  success,
  error,
  promise,
  remove,
  warning,
  info,
  dismiss
};

export { toast, Toaster };
