import React from 'react';
import ShowSvg from '@atlas-design-system/core/dist/svg/show.svg';

import IconBase from './IconBase';

const ShowIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={ShowSvg} />
));

ShowIcon.displayName = 'ShowIcon';

export default ShowIcon;
