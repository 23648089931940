import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import FooterMenu from './FooterMenu';

const Footer = ({ className, logo, copyright, children, ...otherProps }) => (
  <footer className={clsx(['atls-footer', className])} {...otherProps}>
    <div className='footerWrap'>
      {children}
      <div className='branding'>
        {logo}
        {copyright && <small>{copyright}</small>}
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.node,
  copyright: PropTypes.node,
  children: PropTypes.node.isRequired
};

Footer.defaultProps = {
  /**
   * Custom class.
   */
  className: null,
  /**
   * Travelport logo
   */
  logo: null,
  /**
   * Travelport copyright smalltext
   */
  copyright: null
};

Footer.displayName = 'Footer';

Footer.Menu = FooterMenu;

export default Footer;
