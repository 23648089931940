import React from 'react';
import PropTypes from 'prop-types';

import {
  AuthorisedIcon,
  ErrorIcon,
  InformationV2Icon,
  WarningIcon
} from 'components/icon';

const DialogIcon = ({ appearance }) => {
  switch (appearance) {
    case 'success':
      return <AuthorisedIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'information':
      return <InformationV2Icon />;
    case 'error':
      return <ErrorIcon />;
    default:
      return null;
  }
};

DialogIcon.propTypes = {
  appearance: PropTypes.string.isRequired
};

export default DialogIcon;
