import React from 'react';
import PropTypes from 'prop-types';

const Tab = (props) => <React.Fragment {...props} />;

Tab.propTypes = {
  /**
   * The content of the tab panel associated with the tab.
   */
  children: PropTypes.node.isRequired,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * The title of the tab. This is required for accessibility.
   */
  title: PropTypes.string.isRequired,
  /**
   * The subheading message of the tab.
   */
  subHeading: PropTypes.string,
  /**
   * The icon to be used instead of the title.
   */
  icon: PropTypes.node,
  /**
   * Adds custom HTML attribute to tab panels
   */
  tabPanelProps: PropTypes.shape({}),
  /**
   * Sets icon to the start of the tab.
   */
  iconStart: PropTypes.node,
  /**
   * Sets icon to the end of the tab.
   */
  iconEnd: PropTypes.node
};

Tab.defaultProps = {
  className: null,
  subHeading: null,
  icon: null,
  tabPanelProps: {},
  iconStart: null,
  iconEnd: null
};

export default Tab;
