import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import getOverrides from 'utils/getOverrides';
import { avatar } from './blocks';

const getInitials = (name) => {
  const words = name.split(' ');
  const initials = words.map((word) => word[0]);
  return initials.slice(0, 2).join('').toUpperCase();
};

const Avatar = ({ src, name, size, className, overrides, ...otherProps }) => {
  const initials = getInitials(name);
  const [Root, rootProps] = getOverrides('div', overrides.Root);
  const [Initials, initialsProps] = getOverrides('div', overrides.Initials);
  const [Image, imageProps] = getOverrides('img', overrides.Image);

  const getContent = () => {
    if (isValidElement(src)) {
      return src;
    }
    if (typeof src === 'string') {
      return (
        <Image
          className={clsx(avatar('image'))}
          src={src}
          alt={name}
          {...imageProps}
        />
      );
    }
    return (
      <Initials
        role='img'
        className={clsx(avatar('initials', { size }))}
        aria-label={name}
        {...initialsProps}
      >
        {initials}
      </Initials>
    );
  };

  return (
    <Root
      className={clsx(avatar({ size }), className)}
      {...otherProps}
      {...rootProps}
    >
      {getContent()}
    </Root>
  );
};

Avatar.propTypes = {
  /**
   * Ability to Overrides inner components and props.
   */
  overrides: PropTypes.shape({
    Root: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    Initials: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    Image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])
  }),
  /**
   * Custom class.
   */
  className: PropTypes.string,
  /**
   * src of the image or react node.
   */
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Profile name for the initials.
   */
  name: PropTypes.string,
  /**
   * Defines the size of the badge.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge'])
};

Avatar.defaultProps = {
  overrides: {},
  className: null,
  src: null,
  name: null,
  size: 'small'
};

export default Avatar;
