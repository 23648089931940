import React from 'react';
import PropTypes from 'prop-types';
import noop from 'noop';
import onEnter from 'utils/onEnter';
import { ArrowDownIcon, ArrowUpIcon } from 'components/icon';
import onSpace from 'utils/onSpace';
import { collapsible } from './blocks';

const getIconWidth = (value) => ({ medium: 24, large: 32 }[value] || 16);

const CollapsibleHeader = ({
  onClick,
  isExpanded,
  size,
  padding,
  children
}) => {
  const handleKeyDown = (event) => {
    onEnter(onClick)(event);
    onSpace(onClick)(event);
  };

  return (
    <div
      tabIndex='0'
      role='button'
      aria-expanded={isExpanded}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      className={collapsible('header', { size, padding })}
    >
      {isExpanded ? (
        <ArrowUpIcon width={getIconWidth(size)} />
      ) : (
        <ArrowDownIcon width={getIconWidth(size)} />
      )}
      <div className={collapsible('title')}>{children}</div>
    </div>
  );
};

CollapsibleHeader.propTypes = {
  /**
   * Defines the callback executed on the collapsibleHeader click.
   */
  onClick: PropTypes.func,
  /**
   * Defines if the collapsible is expanded or is not expanded.
   */
  isExpanded: PropTypes.bool,
  /**
   * Defines the font size.
   */
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  /**
   * Defines the padding wrapping the text.
   */
  padding: PropTypes.oneOf(['all', 'top-bottom']),
  /**
   * Defines the content of the title.
   */
  children: PropTypes.node
};

CollapsibleHeader.defaultProps = {
  onClick: noop,
  isExpanded: false,
  size: 'small',
  padding: 'all',
  children: null
};

export default CollapsibleHeader;
