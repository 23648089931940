import React from 'react';
import ArrowDownSvg from '@atlas-design-system/core/dist/svg/arrow-down.svg';

import IconBase from './IconBase';

const ArrowDownIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={ArrowDownSvg} />
));

ArrowDownIcon.displayName = 'ArrowDownIcon';

export default ArrowDownIcon;
