import React from 'react';
import Spinner from 'components/spinner';

export const defaultNoOptionsMessage = ({ inputValue }) =>
  inputValue ? 'No options' : 'Type to search';

export const defaultLoadingMessage = () => <Spinner size='mini' />;

export const getIsClearable = ({ isClearable, isMulti }) => {
  if (isClearable === undefined && isMulti) {
    return true;
  }

  return isClearable;
};

export const getCloseMenuOnSelect = ({ closeMenuOnSelect, isMulti }) => {
  if (closeMenuOnSelect === undefined && isMulti) {
    return false;
  }

  return closeMenuOnSelect;
};
