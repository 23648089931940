import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useKey, useClickAway, useLockBodyScroll } from 'react-use';
import clsx from 'clsx';
import useTrapFocus from 'hooks/useTrapFocus';
import useFocusLastOutSideActiveElementEffect from 'hooks/useFocusLastOutSideActiveElementEffect';
import Portal from 'components/portal';
import HTMLElementType from 'utils/HTMLElementType';
import Header from './Header';

const Modal = ({
  id,
  className,
  hidden,
  children,
  title,
  actionButtons,
  footer,
  onDismiss,
  size,
  focusTriggerAfterClose,
  appendTo,
  modalProps,
  ...otherProps
}) => {
  const modalRef = useRef();
  const titleId = `${id}Label`;

  useTrapFocus(modalRef, !hidden);
  useClickAway(modalRef, onDismiss);
  useLockBodyScroll(!hidden);
  useKey((event) => event.key === 'Escape' && !hidden, onDismiss);
  useFocusLastOutSideActiveElementEffect(
    modalRef,
    !hidden && focusTriggerAfterClose
  );

  return hidden ? null : (
    <Portal container={appendTo}>
      <div
        className={clsx('atls-modal', className)}
        hidden={hidden}
        {...otherProps}
      >
        <div
          ref={modalRef}
          id={id}
          className='innerModal'
          role='dialog'
          aria-modal='true'
          aria-labelledby={titleId}
          data-size={size}
          {...modalProps}
        >
          <Header titleId={titleId} onDismiss={onDismiss} {...modalProps}>
            {title}
          </Header>
          <div className='content'>{children}</div>
          {!actionButtons && !footer ? null : (
            <footer>
              {footer || <div className='actionButtons'>{actionButtons}</div>}
            </footer>
          )}
        </div>
      </div>
    </Portal>
  );
};

Modal.propTypes = {
  /**
   * The id of the modal.
   */
  id: PropTypes.string.isRequired,
  /**
   * Sets a custom class to the modal.
   */
  className: PropTypes.string,
  /**
   * The title of the modal.
   */
  title: PropTypes.string.isRequired,
  /**
   * The modal content.
   */
  children: PropTypes.node.isRequired,
  /**
   * Callback fired when the user click on the close button or outside the modal.
   */
  onDismiss: PropTypes.func.isRequired,
  /**
   * If `true` the modal will be hidden.
   */
  hidden: PropTypes.bool,
  /**
   * The size of the modal.
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * The action buttons of the modal.
   */
  actionButtons: PropTypes.node,
  /**
   * Custom footer for the modal. This will replace the default footer and action buttons.
   */
  footer: PropTypes.node,
  /**
   * If `true` the trigger will be focused after the dialog has been closed.
   */
  focusTriggerAfterClose: PropTypes.bool,
  /**
   * The element to append the `Modal` to.
   * Sometimes the `Modal` needs to be appended to a different DOM context due to accessibility or z-index issues.
   */
  appendTo: PropTypes.oneOfType([PropTypes.func, HTMLElementType]),
  /**
   * Adds custom HTML attribute to the modal
   */
  modalProps: PropTypes.shape({})
};

Modal.defaultProps = {
  className: null,
  hidden: true,
  size: 'medium',
  actionButtons: null,
  footer: null,
  focusTriggerAfterClose: false,
  appendTo: null,
  modalProps: {}
};

export default Modal;
