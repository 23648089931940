import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = React.memo(({ duration, isPaused, visible }) => {
  if (!visible) {
    return null;
  }

  const style = {
    animationDuration: `${duration}ms`,
    animationPlayState: isPaused ? 'paused' : 'running',
    opacity: visible ? 1 : 0
  };

  return (
    <div
      role='progressbar'
      aria-label='notification timer'
      className='indicator'
      style={style}
    />
  );
});

ProgressBar.displayName = 'ProgressBar';
ProgressBar.propTypes = {
  duration: PropTypes.number.isRequired,
  isPaused: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired
};

const ConnectedProgressBar = ({ duration, paused, visible }) => (
  <ProgressBar duration={duration} isPaused={paused} visible={visible} />
);
ConnectedProgressBar.propTypes = {
  duration: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  paused: PropTypes.bool.isRequired
};

export default ConnectedProgressBar;
