import React from 'react';
import HideSvg from '@atlas-design-system/core/dist/svg/hide.svg';

import IconBase from './IconBase';

const HideIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={HideSvg} />
));

HideIcon.displayName = 'HideIcon';

export default HideIcon;
