import React from 'react';
import ArrowLeftSvg from '@atlas-design-system/core/dist/svg/arrow-left.svg';

import IconBase from './IconBase';

const ArrowLeftIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={ArrowLeftSvg} />
));

ArrowLeftIcon.displayName = 'ArrowLeftIcon';

export default ArrowLeftIcon;
