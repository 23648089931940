import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Typography = React.forwardRef(
  (
    {
      variant,
      children,
      as: Component,
      bold,
      underline,
      className,
      ...otherProps
    },
    ref
  ) => (
    <Component
      ref={ref}
      className={clsx(
        variant,
        bold && 'atls-font-weight-bold',
        underline && 'atls-font-underline',
        className
      )}
      {...otherProps}
    >
      {children}
    </Component>
  )
);

Typography.propTypes = {
  /**
   * Applies the correct Atlas typography style.
   * @default contentText1
   */
  variant: PropTypes.oneOf([
    'heroText',
    'displayText1',
    'displayText2',
    'displayText3',
    'displayText4',
    'displayText5',
    'displayText6',
    'displayText7',
    'contentText1',
    'contentText2'
  ]),
  /**
   * Visible text.
   */
  children: PropTypes.node.isRequired,
  /**
   * The HTML element or component used for the root node of the text.
   * For example, you can render text as `h1` or `div` element.
   * @default span
   */
  as: PropTypes.elementType,
  /**
   * Sets text bold.
   * @default false
   */
  bold: PropTypes.bool,
  /**
   * Sets text underline.
   * @default false
   */
  underline: PropTypes.bool,
  /**
   * Sets a custom class to the text element.
   */
  className: PropTypes.string,
  /**
   * Adds a test id.
   */
  'data-testid': PropTypes.string
};

Typography.defaultProps = {
  variant: 'contentText1',
  as: 'span',
  bold: false,
  underline: false,
  className: null,
  'data-testid': null
};

Typography.displayName = 'Typography';

export default Typography;
