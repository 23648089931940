import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useListNavigation from 'hooks/useListNavigation';
import { useScroll } from 'react-use';
import { profileMenu } from './blocks';

const Main = ({ children, className, ...otherProps }) => {
  const mainRef = useRef(null);
  const { y } = useScroll(mainRef);
  useListNavigation(mainRef);
  return (
    <ul
      className={clsx(profileMenu('main', { scrolled: y > 0 }), className)}
      role='menu'
      tabIndex={0}
      ref={mainRef}
      {...otherProps}
    >
      {children}
    </ul>
  );
};

Main.propTypes = {
  /**
   * Children nodes of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string
};

Main.defaultProps = {
  children: null,
  className: null
};

Main.displayName = 'ProfileMenu.Main';

export default Main;
