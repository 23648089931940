import React from 'react';
import { accentA600 } from '@atlas-design-system/tokens';
import mergeWithBaseOptions from './mergeWithBaseOptions';
import ToastIcon from '../ToastIcon';

const getLoadingOptions = (options) => {
  const loadingOptions = {
    style: {
      background: accentA600
    },
    icon: <ToastIcon appearance='loading' />,
    appearance: 'loading'
  };

  const mergedOptions = mergeWithBaseOptions(loadingOptions, options);
  return mergedOptions;
};

export default getLoadingOptions;
