// TODO: Fix prop-types
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ToastBar as OriginalToastBar } from 'react-hot-toast';
import { toast } from './blocks';
import dismiss from './methods/dismiss';
import ToastIcon from './ToastIcon';
import ProgressBar from './ProgressBar';
import ToastContext from './toastContext';

const ToastBar = ({ toast: t }) => {
  const { paused, setPaused } = useContext(ToastContext);
  const pause = () => setPaused(true);
  const resume = () => setPaused(false);
  const onDismiss = () => {
    dismiss(t.id);
  };

  return (
    <OriginalToastBar toast={t}>
      {({ icon, message }) => (
        <div
          onMouseEnter={pause}
          onMouseLeave={resume}
          className='wrapper'
          aria-label={t.appearance}
          {...t.customAttributes}
        >
          <div className={toast('content')}>
            <span className='icon'>{icon}</span>
            {message}
            {t.type !== 'loading' && (
              <>
                <div className='closeIcon'>
                  <button
                    onClick={onDismiss}
                    type='button'
                    aria-label='dismiss button'
                  >
                    <ToastIcon appearance='close' />
                  </button>
                </div>
              </>
            )}
          </div>
          {t.showProgressBar && (
            <div className={toast('progress')}>
              <ProgressBar
                duration={t.duration}
                visible={t.visible}
                paused={paused}
              />
            </div>
          )}
        </div>
      )}
    </OriginalToastBar>
  );
};

ToastBar.propTypes = {
  toast: PropTypes.shape({}).isRequired
};

export default ToastBar;
