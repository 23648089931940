import React from 'react';
import PropTypes from 'prop-types';
import generateTabIds from './generateTabIds';

const TabPanel = ({
  tabsId,
  children,
  tabKey,
  isActive,
  renderAll,
  ...otherProps
}) => {
  const [tabId, tabPanelId] = generateTabIds(tabsId, tabKey);

  return (
    <div
      id={tabPanelId}
      role='tabpanel'
      aria-labelledby={tabId}
      tabIndex={0}
      hidden={!isActive}
      {...otherProps}
    >
      {(renderAll || isActive) && children}
    </div>
  );
};

TabPanel.propTypes = {
  tabsId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tabKey: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  renderAll: PropTypes.bool.isRequired
};

export default TabPanel;
