import React from 'react';
import ArrowRightSvg from '@atlas-design-system/core/dist/svg/arrow-right.svg';

import IconBase from './IconBase';

const ArrowRightIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={ArrowRightSvg} />
));

ArrowRightIcon.displayName = 'ArrowRightIcon';

export default ArrowRightIcon;
