import React from 'react';
import AlertV2Svg from '@atlas-design-system/core/dist/svg/alert-v2.svg';

import IconBase from './IconBase';

const AlertV2Icon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={AlertV2Svg} />
));

AlertV2Icon.displayName = 'AlertV2Icon';

export default AlertV2Icon;
