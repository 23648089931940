import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { profileMenu } from './blocks';

const Description = ({ children, className, ...otherProps }) => (
  <div className={clsx(profileMenu('description'), className)} {...otherProps}>
    {children}
  </div>
);

Description.propTypes = {
  /**
   * Children nodes of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string
};

Description.defaultProps = {
  children: null,
  className: null
};

Description.displayName = 'ProfileMenu.Description';

export default Description;
