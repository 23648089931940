import React from 'react';
import { statusWarningBase } from '@atlas-design-system/tokens';
import mergeWithBaseOptions from './mergeWithBaseOptions';
import ToastIcon from '../ToastIcon';

const getWarningOptions = (options) => {
  const warningOptions = {
    style: {
      background: statusWarningBase,
      color: '#000'
    },
    icon: <ToastIcon appearance='warning' />,
    appearance: 'warning'
  };
  const mergedOptions = mergeWithBaseOptions(warningOptions, options);
  return mergedOptions;
};

export default getWarningOptions;
