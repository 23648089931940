import React from 'react';
import ArrowUpSvg from '@atlas-design-system/core/dist/svg/arrow-up.svg';

import IconBase from './IconBase';

const ArrowUpIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={ArrowUpSvg} />
));

ArrowUpIcon.displayName = 'ArrowUpIcon';

export default ArrowUpIcon;
