import React, {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  FloatingPortal,
  FloatingNode,
  FloatingFocusManager
} from '@floating-ui/react-dom-interactions';
import { useDropdownMenu } from './hooks/useDropdownMenu';
import Trigger from './Trigger';
import TriggerMenu from './TriggerMenu';

const Menu = forwardRef(
  (
    {
      children,
      label,
      className,
      renderContent,
      trigger,
      onSubMenuItemClick,
      triggerRef,
      isOpen,
      setIsOpen,
      ...props
    },
    ref
  ) => {
    const {
      context,
      reference,
      getFloatingProps,
      getItemProps,
      getReferenceProps,
      nodeId,
      nested,
      open
    } = useDropdownMenu({
      children,
      onSubMenuItemClick,
      ref,
      isOpen,
      setIsOpen
    });

    useLayoutEffect(() => {
      if (triggerRef) {
        reference(triggerRef.current);
      }
    }, [triggerRef, reference]);

    const getMainTriggerNode = () => {
      if (triggerRef) {
        return null;
      }
      return typeof trigger === 'function' ? (
        trigger(getReferenceProps(props))
      ) : (
        <Trigger trigger={trigger} {...getReferenceProps(props)} />
      );
    };

    return (
      <FloatingNode id={nodeId}>
        {nested ? (
          // TODO: check better approach with overrides
          <TriggerMenu {...getReferenceProps(props)}>{label}</TriggerMenu>
        ) : (
          getMainTriggerNode()
        )}
        <FloatingPortal>
          {open && (
            <FloatingFocusManager
              context={context}
              preventTabbing
              modal={!nested}
              // Touch-based screen readers will be able to navigate back to the
              // reference and click it to dismiss the menu without clicking an item.
              // This acts as a touch-based `Esc` key. A visually-hidden dismiss button
              // is an alternative.
              order={['reference', 'content']}
            >
              <div {...getFloatingProps({ className })}>
                {Children.map(
                  children,
                  (child, index) =>
                    isValidElement(child) &&
                    cloneElement(child, {
                      renderLabel: renderContent,
                      ...getItemProps({
                        index,
                        key: child.key,
                        props: child.props
                      })
                    })
                )}
              </div>
            </FloatingFocusManager>
          )}
        </FloatingPortal>
      </FloatingNode>
    );
  }
);

Menu.displayName = 'DropdownMenu.Menu';

Menu.propTypes = {
  /**
   * Children nodes of the component.
   */
  children: PropTypes.node,
  /**
   * Content of the button trigger.
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Custom classname applied.
   */
  className: PropTypes.string,
  /**
   * Render function to overrides the content.
   */
  renderContent: PropTypes.func,
  /**
   * The trigger for the menu.
   */
  trigger: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * Callback on item click.
   */
  onSubMenuItemClick: PropTypes.func,
  /**
   * The reference of the external trigger for the menu.
   */
  triggerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  /**
   * The state of the dropdown in the controlled mode.
   */
  isOpen: PropTypes.bool,
  /**
   * The callback to set the state of the dropdown in the controlled mode.
   */
  setIsOpen: PropTypes.func
};

Menu.defaultProps = {
  children: null,
  label: null,
  className: null,
  renderContent: null,
  trigger: null,
  onSubMenuItemClick: null,
  triggerRef: null,
  isOpen: null,
  setIsOpen: null
};

export default Menu;
