import { useEffect } from 'react';
// Determine if "hover" logic can run based on the modality of input. This
// prevents unwanted focus synchronization as menus open and close with
// keyboard navigation and the cursor is resting on the menu.

const useOpenSubMenusOnHover = ({ allowHover, setAllowHover }) => {
  useEffect(() => {
    function onPointerMove() {
      setAllowHover(true);
    }

    function onKeyDown() {
      setAllowHover(false);
    }

    window.addEventListener('pointermove', onPointerMove, {
      once: true,
      capture: true
    });
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('pointermove', onPointerMove, {
        capture: true
      });
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [setAllowHover, allowHover]);
};

export default useOpenSubMenusOnHover;
