import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import noop from 'noop';

const Button = React.forwardRef(
  (
    {
      appearance,
      children,
      className,
      disabled,
      size,
      icon,
      iconAlign,
      type,
      onClick,
      ...otherProps
    },
    ref
  ) => (
    <button
      ref={ref}
      className={clsx(['atls-button', className])}
      onClick={onClick}
      data-appearance={appearance}
      data-size={size}
      data-icon-align={iconAlign}
      data-icon-only={(icon && !children) || null}
      // https://github.com/yannickcr/eslint-plugin-react/issues/1846
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      {...otherProps}
    >
      {icon}
      {children}
    </button>
  )
);

Button.displayName = 'Button';

Button.propTypes = {
  /**
   * Defines the appearance of the button.
   */
  appearance: PropTypes.oneOf([
    'primary',
    'primaryReverse',
    'secondary',
    'secondaryReverse',
    'tertiary',
    'destructive',
    'primaryLink',
    'secondaryLink'
  ]),
  /**
   * Visible label.
   */
  children: PropTypes.node,
  /**
   * Sets a custom class to the button.
   */
  className: PropTypes.string,
  /**
   * Sets the state of the button to disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Sets the icon component of the button.
   */
  icon: PropTypes.node,
  /**
   * Sets the icon alignment of the button.
   */
  iconAlign: PropTypes.oneOf(['right', 'left']),
  /**
   * Defines the size of the button.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Function called when button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Defines the type of the button.
   */
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
};

Button.defaultProps = {
  appearance: 'secondary',
  children: null,
  className: null,
  disabled: false,
  icon: null,
  iconAlign: 'left',
  onClick: noop,
  size: 'medium',
  type: 'button'
};

export default Button;
