/* eslint-disable react/prop-types */
import noop from 'noop';
import React from 'react';
import Chip from 'components/chip';

const MultiValue = (props) => {
  const { selectProps, index, removeProps, isFocused, cx, children } = props;
  const { singleValue, value } = selectProps;
  const { onTouchEnd, onMouseDown, onClick } = removeProps;
  if (singleValue) {
    if (index !== 0) {
      return null;
    }

    const selectedCount = value.length;
    let singleValueContent;

    if (typeof singleValue === 'function') {
      singleValueContent = (
        <>
          {selectedCount} {singleValue(value)}
        </>
      );
    } else {
      singleValueContent = (
        <>
          {selectedCount} {singleValue === true ? 'selected' : singleValue}
        </>
      );
    }

    return (
      <div
        className={cx({
          single: true
        })}
      >
        {singleValueContent}
      </div>
    );
  }

  return (
    <Chip
      className={cx({
        chip: true
      })}
      value={children}
      selected={isFocused}
      onClick={noop}
      onClose={onClick}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
    />
  );
};

export default MultiValue;
