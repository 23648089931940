import React from 'react';
import PropTypes from 'prop-types';
import TravelportSvg from '@atlas-design-system/core/dist/logos/travelport.svg';

import LogoBase from './LogoBase';

const TravelportLogo = React.forwardRef((props, ref) => (
  <LogoBase {...props} ref={ref} Logo={TravelportSvg} />
));

TravelportLogo.propTypes = {
  className: PropTypes.string
};

TravelportLogo.defaultProps = {
  className: null
};

TravelportLogo.displayName = 'TravelportLogo';

export default TravelportLogo;
