import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useUID } from 'react-uid';
import { AlertV2Icon, InformationIcon } from 'components/icon';
import Tooltip from 'components/tooltip';

const isNewSelect = ({
  options,
  cacheOptions,
  defaultOptions,
  loadingMessage,
  loadOptions
}) => {
  if (
    options ||
    cacheOptions ||
    defaultOptions ||
    loadingMessage ||
    loadOptions
  ) {
    return true;
  }
  return false;
};

const FormControl = ({
  id,
  className,
  children,
  label,
  hint,
  error,
  disabled,
  isRequired,
  showRequiredIndicator,
  tooltipContent,
  tooltipProps,
  hideLabel,
  htmlFor,
  ...otherProps
}) => {
  const childrenProps = React.Children.only(children).props;
  const generatedUid = useUID();
  const childrenId = childrenProps.id || `atls-form-control-${generatedUid}`;

  const renderNecessityIndicator = () => {
    if (showRequiredIndicator && isRequired) {
      return (
        <div className='requiredIndicator'>
          <span aria-hidden='true'>*</span>
        </div>
      );
    }
    return null;
  };

  const renderAdditionalInfo = () => {
    if (error && typeof error === 'string') {
      return (
        <span
          id={id && `${id}-error`}
          className='errorMessage'
          aria-live='assertive'
        >
          <AlertV2Icon className='alertIcon' />
          <div>{error}</div>
        </span>
      );
    }
    if (hint) {
      return (
        <span id={id && `${id}-hint`} className='hintText'>
          {hint}
        </span>
      );
    }
    return null;
  };

  return (
    <div
      id={id}
      className={clsx(['atls-formControl', className, { error, disabled }])}
      {...otherProps}
    >
      {hideLabel ? null : (
        <div className='labelContent'>
          <label htmlFor={htmlFor || childrenId}>{label}</label>
          {renderNecessityIndicator()}
          {tooltipContent && (
            <Tooltip content={tooltipContent} {...tooltipProps}>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <span tabIndex={0}>
                <InformationIcon />
              </span>
            </Tooltip>
          )}
        </div>
      )}

      {React.cloneElement(children, {
        ...children.props,
        // Apply different props for the select componenent. Review this for other input components in the future.
        ...(isNewSelect(children.props)
          ? { inputId: childrenId, isDisabled: disabled }
          : { id: childrenId, disabled }),
        required: isRequired
      })}
      {renderAdditionalInfo()}
    </div>
  );
};

FormControl.propTypes = {
  /**
   * Sets an ID.
   */
  id: PropTypes.string,
  /**
   * Sets a custom class.
   */
  className: PropTypes.string,
  /**
   * Add a form element/Atlas input.
   */
  children: PropTypes.node.isRequired,
  /**
   * Sets a text label.
   */
  label: PropTypes.string.isRequired,
  /**
   * Sets hint text.
   */
  hint: PropTypes.string,
  /**
   * Sets an error message that overrides hint text.
   */
  error: PropTypes.string,
  /**
   * Sets a custom class.
   */
  disabled: PropTypes.bool,
  /**
   * Boolean to set if an input is required
   */
  isRequired: PropTypes.bool,
  /**
   * Boolean to show necessity indicator
   */
  showRequiredIndicator: PropTypes.bool,
  /**
   * Connects the label with the form element.
   */
  htmlFor: PropTypes.string,
  /**
   * Sets a tooltip content.
   */
  tooltipContent: PropTypes.node,
  /**
   * Adds props to the tooltip.
   */
  tooltipProps: PropTypes.shape({}),
  /**
   * Sets label visibility.
   */
  hideLabel: PropTypes.bool
};

FormControl.defaultProps = {
  className: null,
  hint: null,
  error: null,
  disabled: false,
  isRequired: false,
  showRequiredIndicator: false,
  tooltipContent: null,
  tooltipProps: {},
  htmlFor: null,
  id: null,
  hideLabel: false
};

export default FormControl;
