import {
  Children,
  isValidElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import {
  useListNavigation,
  useHover,
  useTypeahead,
  useInteractions,
  useRole,
  useClick,
  useDismiss,
  safePolygon,
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useFloatingParentNodeId,
  useFloatingNodeId,
  useFloatingTree
} from '@floating-ui/react-dom-interactions';
import mergeRefs from 'react-merge-refs';
import useCloseAllMenusOnItemClick from './useCloseAllMenusOnItemClick';
import useOpenSubMenusOnHover from './useOpenSubMenusOnHover';
import { dropdownMenu } from '../blocks';
import DropdownMenuContext from '../dropdownMenuContext';

export const useDropdownMenu = ({
  children,
  onSubMenuItemClick,
  ref,
  isOpen,
  setIsOpen
}) => {
  const isControlled = isOpen !== null;
  const [internalOpen, setInternalOpen] = useState(false);
  const open = isControlled ? isOpen : internalOpen;
  const setOpen = isControlled ? setIsOpen : setInternalOpen;
  const [activeIndex, setActiveIndex] = useState(null);
  const [allowHover, setAllowHover] = useState(true);
  const openMenuOnHover = false;

  const { onItemSelection, onToggle } = useContext(DropdownMenuContext);

  const listItemsRef = useRef([]);
  const listContentRef = useRef(
    Children.map(children, (child) =>
      isValidElement(child) ? child.props.label : null
    )
  );

  useEffect(() => {
    if (onToggle) {
      onToggle(open);
    }
  }, [open, onToggle]);

  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const tree = useFloatingTree();
  const nested = parentId != null;

  const { x, y, reference, floating, strategy, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: 8, alignmentAxis: 0 }), flip(), shift()],
    placement: nested ? 'right-start' : 'bottom-start',
    nodeId,
    whileElementsMounted: autoUpdate
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useHover(context, {
        handleClose: safePolygon({ restMs: 25 }),
        delay: { open: 75 },
        enabled: !!openMenuOnHover && nested && allowHover
      }),
      useClick(context, {
        toggle: !nested,
        pointerDown: true
        // ignoreMouse: nested
      }),
      useRole(context, { role: 'menu' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef: listItemsRef,
        activeIndex,
        nested,
        onNavigate: setActiveIndex
      }),
      useTypeahead(context, {
        listRef: listContentRef,
        onMatch: open ? setActiveIndex : undefined,
        activeIndex
      })
    ]
  );

  useCloseAllMenusOnItemClick({ setOpen, parentId, tree, refs });
  useOpenSubMenusOnHover({ allowHover, setAllowHover });

  const mergedReferenceRef = useMemo(
    () => mergeRefs([ref, reference]),
    [reference, ref]
  );

  const proxyGetFloatingProps = ({ className }) =>
    getFloatingProps({
      className: clsx(
        dropdownMenu(),
        className !== dropdownMenu('item') && className
      ),
      ref: floating,
      style: {
        position: strategy,
        top: y ?? '',
        left: x ?? '',
        // Prevent focus events from firing on a nested submenu before
        // it's been positioned
        visibility: allowHover && x == null ? 'hidden' : undefined
      }
    });

  const proxyGetReferenceProps = (props) =>
    getReferenceProps({
      ...props,
      ref: mergedReferenceRef,
      onClick(event) {
        event.stopPropagation();
        event.currentTarget.focus();
      },
      ...(nested && {
        className: dropdownMenu('item'),
        role: 'menuitem',
        onKeyDown(event) {
          // Prevent more than one menu from being open.
          if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            setOpen(false);
          }
        }
      })
    });

  const proxyGetItemProps = ({ index, key, props }) =>
    getItemProps({
      key,
      role: 'menuitem',
      className: clsx([
        dropdownMenu('item', { disabled: props.disabled }),
        props.className
      ]),
      ref(node) {
        listItemsRef.current[index] = node;
      },
      onClick() {
        if (onSubMenuItemClick) {
          onSubMenuItemClick(key);
        } else if (onItemSelection) {
          onItemSelection(key);
        }
        if (tree) {
          tree.events.emit('click');
        }
      },
      // By default `focusItemOnHover` uses `mousemove` to sync focus,
      // but when a menu closes we want this to sync it on `enter`
      // even if the cursor didn't move. NB: Safari does not sync in
      // this case.
      onPointerEnter() {
        if (allowHover) {
          setActiveIndex(index);
        }
      }
    });

  return {
    reference,
    refs,
    context,
    getFloatingProps: proxyGetFloatingProps,
    getReferenceProps: proxyGetReferenceProps,
    getItemProps: proxyGetItemProps,
    nodeId,
    nested,
    open
  };
};
