import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const ZeroWidthSpace = () => '\u200B';

const Link = React.forwardRef(
  (
    {
      appearance,
      children,
      className,
      disabled,
      iconLeft,
      iconRight,
      mode,
      href,
      as: Component,
      ...otherProps
    },
    ref
  ) => (
    <Component
      ref={ref}
      href={href}
      className={clsx(['atls-link', className])}
      data-appearance={appearance}
      data-mode={mode}
      disabled={disabled}
      {...otherProps}
    >
      {iconLeft}
      {children ?? <ZeroWidthSpace />}
      {iconRight}
    </Component>
  )
);

Link.propTypes = {
  href: PropTypes.string,
  /**
   * Defines the appearance of the Link.
   */
  appearance: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /**
   * Visible label.
   */
  children: PropTypes.string,
  /**
   * Sets a custom class to the Link.
   */
  className: PropTypes.string,
  /**
   * Sets the state of the Link to disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Sets the left aligned icon component of the Link.
   */
  iconLeft: PropTypes.node,
  /**
   * Sets the right aligned icon component of the Link.
   */
  iconRight: PropTypes.node,
  /**
   * Defines the type of the Link.
   */
  mode: PropTypes.oneOf(['inline', 'standalone']),
  /**
   * Sets the component to be rendered.
   *  Use this prop if you want to use a custom component.
   * For example, you can use it with `react-router` to render a `<Link>` component. The default component is `<a>`.
   */
  as: PropTypes.elementType
};

Link.defaultProps = {
  appearance: 'primary',
  children: null,
  className: null,
  disabled: false,
  href: null,
  iconLeft: null,
  iconRight: null,
  mode: 'inline',
  as: 'a'
};

Link.displayName = 'Link';

export default Link;
