import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import noop from 'noop';
import Stack from './Stack';
import {
  AuthorisedIcon,
  ErrorIcon,
  InformationV2Icon,
  WarningIcon,
  CloseV2Icon
} from '../icon';
import OverflowContent from './OverflowContent';

const getIcon = (appearance) => {
  switch (appearance) {
    case 'success':
      return <AuthorisedIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'information':
    default:
      return <InformationV2Icon />;
  }
};

const Banner = ({
  id,
  className,
  heading,
  appearance,
  size,
  dismissable,
  children,
  onClose,
  overflow,
  ...otherProps
}) => (
  <div
    role='alert'
    id={id}
    className={clsx(['atls-banner', className])}
    data-appearance={appearance}
    data-size={size}
    aria-label={appearance}
    {...otherProps}
  >
    {dismissable && (
      <div className='close'>
        <button type='button' title='Close' onClick={onClose} tabIndex={0}>
          <CloseV2Icon />
        </button>
      </div>
    )}
    <div className='icon'>{getIcon(appearance)}</div>
    <div className='content'>
      {heading && <h1>{heading}</h1>}
      {overflow ? (
        <OverflowContent
          size={size}
          {...(typeof overflow === 'object' ? overflow : {})}
        >
          {children}
        </OverflowContent>
      ) : (
        children
      )}
    </div>
  </div>
);

Banner.propTypes = {
  /**
   * Adds an ID.
   */
  id: PropTypes.string,
  /**
   * Adds a class or classes.
   */
  className: PropTypes.string,
  /**
   * Adds text or children.
   */
  children: PropTypes.node,
  /**
   * Adds a heading.
   */
  heading: PropTypes.string,
  /**
   * Sets the appearance colours & icon.
   */
  appearance: PropTypes.oneOf(['success', 'warning', 'error', 'information'])
    .isRequired,
  /**
   * Sets the size.
   */
  size: PropTypes.oneOf(['medium', 'small']),
  /**
   * Shows/hides the close icon
   */
  dismissable: PropTypes.bool,
  /**
   * Callback when the close button is clicked.
   */
  onClose: PropTypes.func,
  /**
   * Shows/hides the overflow content. Check the `OverflowContent` component for more info.
   */
  overflow: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
};

Banner.defaultProps = {
  id: null,
  className: null,
  children: null,
  heading: null,
  size: 'medium',
  dismissable: false,
  onClose: noop,
  overflow: false
};

Banner.Stack = Stack;
export default Banner;
