import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Spinner = ({
  size,
  primaryLabel,
  secondaryLabel,
  indeterminate,
  percent,
  overlay,
  className,
  children,
  ...otherProps
}) => {
  const calcDeterminateOffset = (percentValue) => {
    const r = 45;
    const c = Math.PI * (r * 2);
    return ((100 - percentValue) / 100) * c;
  };

  const dashOffset = useMemo(() => calcDeterminateOffset(percent), [percent]);

  return (
    <div
      role={indeterminate ? 'alert' : 'progressbar'}
      aria-live='polite'
      aria-valuemin={indeterminate ? null : 0}
      aria-valuemax={indeterminate ? null : 100}
      aria-valuenow={indeterminate ? null : percent}
      aria-label={
        size === 'small' || size === 'mini'
          ? (secondaryLabel && `${primaryLabel} ${secondaryLabel}`) ||
            primaryLabel
          : null
      }
      className={clsx(['atls-spinner', className])}
      data-size={size}
      data-indeterminate={indeterminate}
      data-overlay={overlay}
      {...otherProps}
    >
      <div>
        {size === 'mini' ? (
          <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
            <circle className='track' cx='50' cy='50' r='40' />
            <circle
              className='fill'
              strokeDashoffset={dashOffset}
              cx='50'
              cy='50'
              r='40'
            />
          </svg>
        ) : (
          <svg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
            <circle className='track' cx='50' cy='50' r='45' />
            <circle
              className='fill'
              strokeDashoffset={dashOffset}
              cx='50'
              cy='50'
              r='45'
            />
          </svg>
        )}
        {size !== 'small' && size !== 'mini' && primaryLabel && (
          <div className={clsx(['labels', size === 'large' && 'large-labels'])}>
            <div className='primary'>{primaryLabel}</div>
            {secondaryLabel && (
              <div className='secondary'>{secondaryLabel}</div>
            )}
          </div>
        )}
        {children && <div className='spinner-action'>{children}</div>}
      </div>
    </div>
  );
};

Spinner.propTypes = {
  /**
   * Adds text or children.
   */
  children: PropTypes.node,
  /**
   * Defines the size of the spinner.
   */
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),

  /**
   * Sets the state of the spinner to indeterminate.
   */
  indeterminate: PropTypes.bool,

  /**
   * Sets the percent progress of the spinner component.
   */
  percent: PropTypes.number,

  /**
   * Sets the primary label of the spinner component.
   */
  primaryLabel: PropTypes.string,

  /**
   * Sets the secondary label of the spinner component.
   */
  secondaryLabel: PropTypes.string,

  /**
   * Sets the state of the spinner to add overlay.
   */
  overlay: PropTypes.bool,

  /**
   * Sets a custom class to the button.
   */
  className: PropTypes.string
};

Spinner.defaultProps = {
  children: null,
  size: 'large',
  indeterminate: true,
  className: null,
  primaryLabel: '',
  secondaryLabel: '',
  percent: 0,
  overlay: false
};

export default Spinner;
