import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import getOverrides from 'utils/getOverrides';
import ReactSelect from 'react-select';
import { select } from './blocks';
import getComponentsPropWithOverrides from './getComponentsPropWithOverrides';
import {
  defaultNoOptionsMessage,
  getIsClearable,
  getCloseMenuOnSelect
} from './defaults/props';

const Select = React.forwardRef(
  (
    {
      className,
      closeMenuOnSelect,
      controlShouldRenderValue,
      defaultValue,
      filterOption,
      formatOptionLabel,
      getOptionLabel,
      getOptionValue,
      hideSelectedOptions,
      id,
      inputId,
      isClearable,
      isDisabled,
      isMulti,
      isSearchable,
      menuIsOpen,
      menuPlacement,
      name,
      noOptionsMessage,
      onBlur,
      onChange,
      onFocus,
      onInputChange,
      onKeyDown,
      onMenuClose,
      onMenuOpen,
      options,
      overrides,
      placeholder,
      singleValue,
      value,
      ...otherProps
    },
    ref
  ) => {
    const { 'data-testid': dataTestId } = otherProps;
    const [Root, rootProps] = getOverrides(ReactSelect, overrides.Root);

    const componentsProp = useMemo(
      () => getComponentsPropWithOverrides(overrides),
      [overrides]
    );

    return (
      <Root
        backspaceRemovesValue={!singleValue}
        className={clsx(select(), className)}
        classNamePrefix={select()}
        closeMenuOnSelect={getCloseMenuOnSelect({ closeMenuOnSelect, isMulti })}
        components={componentsProp}
        controlShouldRenderValue={controlShouldRenderValue}
        defaultValue={defaultValue}
        filterOption={filterOption}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        hideSelectedOptions={hideSelectedOptions}
        id={id}
        inputId={inputId}
        isClearable={getIsClearable({ isClearable, isMulti })}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        name={name}
        noOptionsMessage={noOptionsMessage}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        ref={ref}
        options={options}
        placeholder={placeholder}
        singleValue={singleValue}
        value={value}
        data-testid={dataTestId}
        {...rootProps}
      />
    );
  }
);

Select.propTypes = {
  /** Sets a className attribute on the outer component */
  className: PropTypes.string,
  /** Close the select menu when the user selects an option */
  closeMenuOnSelect: PropTypes.bool,
  /** Whether the value of the select, e.g. SingleValue, should be displayed in the control. */
  controlShouldRenderValue: PropTypes.bool,
  /** The default value of the select. */
  defaultValue: PropTypes.shape({}),
  /** Custom method to filter whether an option should be displayed in the menu
   * @param {object}
   * @returns {node}
   */
  filterOption: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Formats option labels in the menu and control as React components
   * @param {object}
   * @returns {node}
   */
  formatOptionLabel: PropTypes.func,
  /** Resolves option data to a string to be displayed as the label by components
   * @param {object}
   * @returns {string}
   */
  getOptionLabel: PropTypes.func,
  /** Resolves option data to a string to compare options and specify value attributes
   * @param {object}
   * @returns {string}
   */
  getOptionValue: PropTypes.func,
  /** Hide the selected option from the menu */
  hideSelectedOptions: PropTypes.bool,
  /* The id to set on the SelectContainer component. */
  id: PropTypes.string,
  /* The id of the search input */
  inputId: PropTypes.string,
  /** Is the select value clearable */
  isClearable: PropTypes.bool,
  /* Is the select disabled */
  isDisabled: PropTypes.bool,
  /** Support multiple selected options */
  isMulti: PropTypes.bool,
  /** Whether to enable search functionality */
  isSearchable: PropTypes.bool,
  /** Whether the menu is open */
  menuIsOpen: PropTypes.bool,
  /**
   * Default placement of the menu in relation to the control.
   * 'auto' will flip when there isn't enough space below the control.
   */
  menuPlacement: PropTypes.oneOf(['auto', 'top', 'bottom']),
  /** Name of the HTML Input (optional - without this, no input will be rendered) */
  name: PropTypes.string,
  /** Text to display when there are no options
   * @param {Object}
   * @returns {node}
   */
  noOptionsMessage: PropTypes.func,
  /** Handle blur events on the control */
  onBlur: PropTypes.func,
  /** Handle change events on the select */
  onChange: PropTypes.func,
  /** Handle focus events on the control */
  onFocus: PropTypes.func,
  /** Handle change events on the input */
  onInputChange: PropTypes.func,
  /** Handle key down events on the select */
  onKeyDown: PropTypes.func,
  /** Handle the menu closing */
  onMenuClose: PropTypes.func,
  /** Handle the menu opening */
  onMenuOpen: PropTypes.func,
  /** Array of options that populate the select menu */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isDisabled: PropTypes.bool
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            isDisabled: PropTypes.bool
          })
        )
      })
    )
  ]),
  /** Override subcompenents */
  overrides: PropTypes.shape({
    Root: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    ClearIndicator: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    DropdownIndicator: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.func
    ]),
    Control: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    MultiValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    LoadingIndicator: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.func
    ]),
    LoadingMessage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    OptionContainer: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])
  }),
  /** Placeholder for the select value */
  placeholder: PropTypes.node,
  /** Whether the value of the select should be displayed using Chips or a single value */
  singleValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.func
  ]),
  /** The value of the select; reflected by the selected option */
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ])
};

Select.defaultProps = {
  className: null,
  closeMenuOnSelect: undefined,
  controlShouldRenderValue: true,
  defaultValue: undefined,
  filterOption: undefined,
  formatOptionLabel: undefined,
  getOptionLabel: undefined,
  getOptionValue: undefined,
  hideSelectedOptions: false,
  id: undefined,
  inputId: undefined,
  isClearable: undefined,
  isDisabled: false,
  isMulti: false,
  isSearchable: false,
  menuPlacement: 'bottom',
  menuIsOpen: undefined,
  name: undefined,
  noOptionsMessage: defaultNoOptionsMessage,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  onInputChange: undefined,
  onKeyDown: undefined,
  onMenuClose: undefined,
  onMenuOpen: undefined,
  options: [],
  overrides: {},
  placeholder: '',
  singleValue: false,
  value: undefined
};

Select.displayName = 'Select';

export default Select;
