import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ArrowRightIcon } from 'components/icon';

const TriggerMenu = forwardRef(({ children, renderLabel, ...props }, ref) => {
  const buttonContent = renderLabel ? (
    renderLabel({ children, ...props })
  ) : (
    <>
      {children}
      <ArrowRightIcon width='16' />
    </>
  );

  return (
    <button ref={ref} type='button' {...props}>
      {buttonContent}
    </button>
  );
});

TriggerMenu.displayName = 'TriggerMenu';

TriggerMenu.propTypes = {
  /**
   * Callback function triggered when Item is selected.
   */
  children: PropTypes.node,
  /**
   * Render function to manipulate the label displayed
   */
  renderLabel: PropTypes.func
};

TriggerMenu.defaultProps = {
  children: null,
  renderLabel: null
};

export default TriggerMenu;
