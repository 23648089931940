import React from 'react';
import HelpReverseSvg from '@atlas-design-system/core/dist/spot-illustrations/help-reverse.svg';

import IllustrationBase from './IllustrationBase';

const HelpReverseIllustration = React.forwardRef((props, ref) => (
  <IllustrationBase {...props} ref={ref} Illustration={HelpReverseSvg} />
));

HelpReverseIllustration.displayName = 'HelpReverseIllustration';

export default HelpReverseIllustration;
