import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';

const Trigger = forwardRef(({ trigger, ...otherProps }, ref) => (
  <Button ref={ref} {...otherProps}>
    {trigger}
  </Button>
));

Trigger.displayName = 'Trigger';

Trigger.propTypes = {
  /**
   * Text in the trigger
   */
  trigger: PropTypes.string
};

Trigger.defaultProps = {
  trigger: null
};

export default Trigger;
