import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import onArrowRight from 'utils/onArrowRight';
import onArrowLeft from 'utils/onArrowLeft';
import onArrowUp from 'utils/onArrowUp';
import onArrowDown from 'utils/onArrowDown';
import onHome from 'utils/onHome';
import onEnd from 'utils/onEnd';
import generateTabIds from './generateTabIds';

const focusNextTab = (tab) => tab.nextSibling.focus();
const focusPreviousTab = (tab) => tab.previousSibling.focus();
const focusFirstTab = (tab) => tab.parentNode.firstChild.focus();
const focusLastTab = (tab) => tab.parentNode.lastChild.focus();

const switchTab = (event, direction) => {
  const { target } = event;
  switch (direction) {
    case 'right':
      target.nextSibling ? focusNextTab(target) : focusFirstTab(target);
      break;
    case 'left':
      target.previousSibling ? focusPreviousTab(target) : focusLastTab(target);
      break;
    case 'down':
      target.nextSibling ? focusNextTab(target) : focusFirstTab(target);
      break;
    case 'up':
      target.previousSibling ? focusPreviousTab(target) : focusLastTab(target);
      break;
    case 'first':
      event.preventDefault();
      focusFirstTab(target);
      break;
    case 'last':
      event.preventDefault();
      focusLastTab(target);
      break;
    default:
      break;
  }
};

const InternalTab = ({
  tabsId,
  title,
  subHeading,
  tabKey,
  isActive,
  setActiveKey,
  icon,
  activateOnFocus,
  layout,
  iconStart,
  iconEnd,
  className,
  ...otherProps
}) => {
  const [tabId, tabPanelId] = generateTabIds(tabsId, tabKey);

  const handleOnClick = () => {
    setActiveKey(tabKey);
  };

  const handleOnKeyDown = (event) => {
    if (layout === 'vertical') {
      onArrowUp(switchTab)(event, 'up');
      onArrowDown(switchTab)(event, 'down');
    } else {
      onArrowRight(switchTab)(event, 'right');
      onArrowLeft(switchTab)(event, 'left');
    }
    onHome(switchTab)(event, 'first');
    onEnd(switchTab)(event, 'last');
  };

  return (
    <button
      id={tabId}
      type='button'
      role='tab'
      aria-selected={isActive}
      aria-controls={tabPanelId}
      aria-label={icon ? title : undefined}
      tabIndex={isActive ? 0 : -1}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      onFocus={activateOnFocus ? handleOnClick : undefined}
      className={clsx([
        subHeading && 'subHeading',
        icon && 'largeIcon',
        className
      ])}
      {...otherProps}
    >
      {iconStart && <div className='smallIcon start'>{iconStart}</div>}
      <div>
        <span>{icon || title}</span>
        {!icon && subHeading && <span>{subHeading}</span>}
      </div>
      {iconEnd && <div className='smallIcon end'>{iconEnd}</div>}
    </button>
  );
};

InternalTab.propTypes = {
  tabsId: PropTypes.string.isRequired,
  tabKey: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  setActiveKey: PropTypes.func.isRequired,
  activateOnFocus: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  icon: PropTypes.node,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  className: PropTypes.string
};

InternalTab.defaultProps = {
  subHeading: null,
  icon: null,
  layout: 'horizontal',
  iconStart: null,
  iconEnd: null,
  className: null
};

InternalTab.displayName = 'Tabs.Tab';

export default InternalTab;
