import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import onEnter from 'utils/onEnter';
import onBackspaceOrDel from 'utils/onBackspaceOrDel';
import { CloseIcon } from 'components/icon';

const Chip = React.forwardRef(
  (
    {
      id,
      className,
      disabled,
      icon,
      hasError,
      onClose,
      onClick,
      selected,
      value,
      chipProps,
      ...otherProps
    },
    ref
  ) => {
    const handleKeyDown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    const handleDeleteDown = (e) => {
      e.preventDefault();
      onClose(e);
    };

    const handleInputOnKeyPress = (event) => {
      if (!disabled) {
        onEnter(handleKeyDown)(event);
        onBackspaceOrDel(handleDeleteDown)(event);
      }
    };

    return (
      <div
        id={id}
        className={clsx(['atls-chip', className])}
        data-clickable={onClick && !disabled}
        data-has-close={onClose !== null}
        data-icon={icon !== null}
        data-has-error={hasError}
        aria-selected={selected}
        aria-disabled={disabled}
        aria-label={hasError ? 'error chip' : 'chip'}
        ref={ref}
        {...otherProps}
      >
        {onClick ? (
          <button
            type='button'
            className='chip-content'
            onClick={onClick}
            onKeyDown={handleInputOnKeyPress}
            tabIndex={disabled ? '-1' : '0'}
            {...chipProps}
          >
            {icon}
            <span>{value}</span>
          </button>
        ) : (
          <div className='chip-content' {...chipProps}>
            {icon}
            <span>{value}</span>
          </div>
        )}
        {onClose && (
          <button
            type='button'
            onClick={onClose}
            aria-label='Close'
            tabIndex={disabled ? '-1' : '0'}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    );
  }
);

Chip.displayName = 'Chip';

Chip.propTypes = {
  /**
   * Adds an ID.
   */
  id: PropTypes.string,
  /**
   * Adds a class or classes.
   */
  className: PropTypes.string,
  /**
   * Flag to disable all interaction and selection on chip.
   */
  disabled: PropTypes.bool,
  /**
   * Flag to set appearance to error.
   */
  hasError: PropTypes.bool,
  /**
   * Sets the left-side svg icon on chip.
   */
  icon: PropTypes.node,
  /**
   * Callback when the close button is clicked.
   */
  onClose: PropTypes.func,
  /**
   * Callback when the chip button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Flag to set appearance to selected.
   */
  selected: PropTypes.bool,
  /**
   * Text content for the chip text and title.
   */
  value: PropTypes.string.isRequired,
  /**
   * Adds custom HTML attribute to chip
   */
  chipProps: PropTypes.shape({})
};

Chip.defaultProps = {
  id: null,
  className: null,
  disabled: null,
  hasError: false,
  icon: null,
  onClose: null,
  onClick: null,
  selected: false,
  chipProps: {}
};

export default Chip;
