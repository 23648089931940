import React from 'react';
import { accentA600 } from '@atlas-design-system/tokens';
import mergeWithBaseOptions from './mergeWithBaseOptions';
import ToastIcon from '../ToastIcon';

const getInfoOptions = (options) => {
  const infoOptions = {
    style: {
      background: accentA600
    },
    icon: <ToastIcon appearance='info' />,
    appearance: 'info'
  };
  const mergedOptions = mergeWithBaseOptions(infoOptions, options);
  return mergedOptions;
};

export default getInfoOptions;
