import React from 'react';
import ErrorSvg from '@atlas-design-system/core/dist/svg/error.svg';

import IconBase from './IconBase';

const ErrorIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={ErrorSvg} />
));

ErrorIcon.displayName = 'AddIcon';

export default ErrorIcon;
