/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import { CloseIcon } from '../../../icon';

const ClearIndicator = (props) => {
  const { cx } = props;
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon
        className={cx({
          'clear-icon': true
        })}
      />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
