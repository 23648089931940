import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// TODO: Accept an array of objects to create the buttons
// [{label: 'Label', size: 'medium'}, {label: 'Label', appearance: 'primary'}]
const ButtonGroup = ({
  id,
  align,
  className,
  children,
  appearance,
  disabled,
  size,
  iconAlign,
  ...otherProps
}) => {
  const isCustom = appearance || disabled || size || iconAlign;
  return (
    <div
      id={id}
      className={clsx(['atls-buttonGroup', className])}
      data-align={align}
      {...otherProps}
    >
      {isCustom
        ? React.Children.map(children, (child) =>
            React.cloneElement(child, {
              ...child.props,
              appearance: appearance || child.props.appearance,
              disabled: disabled || child.props.disabled,
              size: size || child.props.size,
              iconAlign: iconAlign || child.props.iconAlign
            })
          )
        : children}
    </div>
  );
};

ButtonGroup.propTypes = {
  /**
   * Sets an id to the button group.
   */
  id: PropTypes.string,
  /**
   * Sets a custom class to the button group.
   */
  className: PropTypes.string,
  /**
   * Accepts Button component from Atlas.
   */
  children: PropTypes.node,
  /**
   * Overwrite the appearance of the child buttons.
   */
  appearance: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondaryReverse',
    'tertiary',
    'destructive'
  ]),
  /**
   * Overwrite the disabled status of the child buttons.
   */
  disabled: PropTypes.bool,
  /**
   * Overwrite the size of the child buttons.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Overwrite the icon alignment of the child buttons.
   */
  iconAlign: PropTypes.oneOf(['right', 'left']),
  /**
   * Align the buttons inside the button group.
   */
  align: PropTypes.oneOf(['right', 'left', 'center', 'expand'])
};

ButtonGroup.defaultProps = {
  id: null,
  appearance: null,
  children: null,
  className: null,
  disabled: false,
  iconAlign: null,
  size: null,
  align: 'left'
};

export default ButtonGroup;
