import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelect } from 'downshift';

const itemToString = (item) => item.label;

const Select = ({
  circularNavigation,
  className,
  disabled,
  downshiftProps,
  id,
  initialSelectedItem,
  items,
  menuId,
  onSelectedItemChange,
  toggleButtonId,
  size,
  selectMenuProps,
  ...otherProps
}) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps
  } = useSelect({
    circularNavigation,
    id,
    initialSelectedItem,
    items,
    itemToString,
    menuId,
    onSelectedItemChange,
    toggleButtonId,
    ...downshiftProps
  });

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.warn(
      '%cDEPRECATION WARNING:',
      'color:red',
      'You are using a deprecated version of the `Select` component. Use the prop `next` to enable the new `Select` component.'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={id}
      className={clsx('atls-select', className)}
      data-size={size}
      {...otherProps}
    >
      <button type='button' {...getToggleButtonProps({ disabled })}>
        {selectedItem?.label || (
          <span className='select-placeholder'>&nbsp;</span>
        )}
        <svg
          viewBox='0 0 12 8'
          role='presentation'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M1.66896 0.756706C1.25845 0.387247 0.626165 0.420525 0.256706 0.831035C-0.112753 1.24155 -0.0794748 1.87384 0.331035 2.24329L1.66896 0.756706ZM6 6L5.33104 6.74329L6 7.34536L6.66896 6.74329L6 6ZM11.669 2.24329C12.0795 1.87384 12.1128 1.24155 11.7433 0.831035C11.3738 0.420525 10.7415 0.387247 10.331 0.756706L11.669 2.24329ZM0.331035 2.24329L5.33104 6.74329L6.66896 5.25671L1.66896 0.756706L0.331035 2.24329ZM6.66896 6.74329L11.669 2.24329L10.331 0.756706L5.33104 5.25671L6.66896 6.74329Z' />
        </svg>
      </button>
      <div className='menu' {...selectMenuProps}>
        <ul {...getMenuProps()}>
          {isOpen &&
            items.map((item, index) => (
              <li
                className={clsx(
                  selectedItem?.label === item.label && 'selected'
                )}
                key={`${item.value}-key`}
                {...getItemProps({ item, index, disabled: item.disabled })}
              >
                {item.label}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

Select.propTypes = {
  /**
   * Sets a custom class.
   */
  className: PropTypes.string,
  /**
   * Sets the state of the select to disabled.
   */
  disabled: PropTypes.bool,
  /**
   * List of options.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool
    })
  ).isRequired,
  /**
   * Called each time the selected item was changed.
   */
  onSelectedItemChange: PropTypes.func,
  /**
   * Item that should be selected when the select is initialized.
   */
  initialSelectedItem: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool
  }),
  /**
   * Controls the circular keyboard navigation between items.
   * If set to true, when first item is highlighted, the Arrow Up will move highlight to the last item, and viceversa using Arrow Down.
   */
  circularNavigation: PropTypes.bool,
  /**
   * Sets a custom id.
   */
  id: PropTypes.string,
  /**
   * Sets a custom id for the menu.
   */
  menuId: PropTypes.string,
  /**
   * Sets a custom id for the trigger button.
   */
  toggleButtonId: PropTypes.string,
  /**
   * Advanced properties. This Select component uses [Downshift](https://github.com/downshift-js/downshift) library.
   * See the list of advanced properties [here](https://github.com/downshift-js/downshift/tree/master/src/hooks/useSelect).
   */
  downshiftProps: PropTypes.shape({}),
  /**
   * Defines the size of the select.
   */
  size: PropTypes.oneOf(['medium', 'large']),
  /**
   * Adds custom HTML attribute to a select menu
   */
  selectMenuProps: PropTypes.shape({})
};

Select.defaultProps = {
  className: null,
  disabled: false,
  onSelectedItemChange: undefined,
  initialSelectedItem: null,
  id: 'atls-select',
  menuId: 'atls-select-menu',
  toggleButtonId: 'atls-select-toggle-button',
  circularNavigation: false,
  downshiftProps: {},
  size: 'medium',
  selectMenuProps: {}
};

export default Select;
