import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const FormHeader = ({ id, className, header, subtitle }) => (
  <div className={clsx(['atls-form-header', className])}>
    <h3 id={id}>{header}</h3>
    <p>{subtitle}</p>
  </div>
);

FormHeader.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  subtitle: PropTypes.string
};

FormHeader.defaultProps = {
  /**
   * Custom id.
   */
  id: null,
  /**
   * Custom class.
   */
  className: null,
  /**
   * Large header text.
   */
  header: null,
  /**
   * Medium subtitle text.
   */
  subtitle: null
};

export default FormHeader;
