import React from 'react';
import WarningSvg from '@atlas-design-system/core/dist/svg/warning.svg';

import IconBase from './IconBase';

const WarningIcon = React.forwardRef((props, ref) => (
  <IconBase {...props} ref={ref} Icon={WarningSvg} />
));

WarningIcon.displayName = 'WarningIcon';

export default WarningIcon;
