import { useRef } from 'react';
import { useUpdateEffect } from 'react-use';

const useFocusLastOutSideActiveElementEffect = (ref, focus) => {
  const lastOutSideActiveElementRef = useRef();

  useUpdateEffect(() => {
    if (focus && !ref.current?.contains(document.activeElement)) {
      lastOutSideActiveElementRef.current = document.activeElement;
    }

    if (!focus && lastOutSideActiveElementRef.current) {
      lastOutSideActiveElementRef.current.focus();
    }
  }, [focus]);
};

export default useFocusLastOutSideActiveElementEffect;
