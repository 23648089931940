import React from 'react';
import getOverrides from 'utils/getOverrides';
import * as defaultComponents from './defaults/components';

const getComponentsPropWithOverrides = (overrides) => {
  const [ClearIndicator, clearIndicatorProps] = getOverrides(
    defaultComponents.ClearIndicator,
    overrides.ClearIndicator
  );
  const [DropdownIndicator, dropdownIndicatorProps] = getOverrides(
    defaultComponents.DropdownIndicator,
    overrides.DropdownIndicator
  );
  const [Input, inputProps] = getOverrides(
    defaultComponents.Input,
    overrides.Input
  );
  const [MenuList, menuListProps] = getOverrides(
    defaultComponents.MenuList,
    overrides.MenuList
  );
  const [MultiValue, multiValueProps] = getOverrides(
    defaultComponents.MultiValue,
    overrides.MultiValue
  );
  const [LoadingIndicator, loadingIndicatorProps] = getOverrides(
    defaultComponents.LoadingIndicator,
    overrides.LoadingIndicator
  );
  const [LoadingMessage, loadingMessageProps] = getOverrides(
    defaultComponents.LoadingMessage,
    overrides.LoadingMessage
  );
  const [OptionContainer, optionContainerProps] = getOverrides(
    defaultComponents.OptionContainer,
    overrides.OptionContainer
  );
  const [SelectContainer, selectContainerProps] = getOverrides(
    defaultComponents.SelectContainer,
    overrides.SelectContainer
  );

  const [Option, optionProps] = getOverrides(null, overrides.Option);

  const componentsProp = {
    ClearIndicator: (props) => (
      <ClearIndicator {...props} {...clearIndicatorProps} />
    ),
    DropdownIndicator: (props) => (
      <DropdownIndicator {...props} {...dropdownIndicatorProps} />
    ),
    Input: (props) => <Input {...props} {...inputProps} />,
    MenuList: (props) => <MenuList {...props} {...menuListProps} />,
    MultiValue: (props) => <MultiValue {...props} {...multiValueProps} />,
    LoadingIndicator: (props) => (
      <LoadingIndicator {...props} {...loadingIndicatorProps} />
    ),
    LoadingMessage: (props) => (
      <LoadingMessage {...props} {...loadingMessageProps} />
    ),
    Option: (props) =>
      Option ? (
        <OptionContainer {...props} {...optionContainerProps}>
          <Option {...props} {...optionProps} />
        </OptionContainer>
      ) : (
        <OptionContainer {...props} {...optionProps} />
      ),
    IndicatorSeparator: null,
    SelectContainer: (props) => (
      <SelectContainer {...props} {...selectContainerProps} />
    )
  };

  return componentsProp;
};

export default getComponentsPropWithOverrides;
