import React, { useState } from 'react';
import PropTypes from 'prop-types';
import onEnter from 'utils/onEnter';
import clsx from 'clsx';
import CollapsibleHeader from './CollapsibleHeader';
import { collapsible } from './blocks';

const Collapsible = ({
  header,
  children,
  className,
  size,
  padding,
  isOpen: isOpenProp,
  onClick,
  defaultOpened,
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpened);
  const isControlled = isOpenProp !== null;
  const isExpanded = isOpenProp || isOpen;

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (!isControlled) {
      setIsOpen(!isExpanded);
    }
  };

  const headerProps = {
    onClick: handleClick,
    isExpanded,
    size,
    padding
  };
  const accessibilityProps = {
    onKeyDown: onEnter(headerProps.onClick),
    tabIndex: '0',
    role: 'button',
    'aria-expanded': isExpanded
  };

  const getHeader = () => {
    if (typeof header === 'string') {
      return <CollapsibleHeader {...headerProps}>{header}</CollapsibleHeader>;
    }
    if (typeof header === 'function') {
      return header({ ...headerProps, ...accessibilityProps });
    }
    return null;
  };

  return (
    <div className={clsx(collapsible(), className)} {...otherProps}>
      {getHeader()}
      {isExpanded && children}
    </div>
  );
};

Collapsible.propTypes = {
  /**
   * Defines the header of the collapsible.
   */
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * Defines the content of the collapsible.
   */
  children: PropTypes.node,
  /**
   * Sets a custom class to the collapsible.
   */
  className: PropTypes.string,
  /**
   * Defines the size of the collapsible header font.
   */
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  /**
   * Defines the padding of the collapsible header.
   */
  padding: PropTypes.oneOf(['all', 'top-bottom']),
  /**
   * Triggers the controlled mode of the collapsible.
   */
  isOpen: PropTypes.bool,
  /**
   * Defines if the collapsible should render opened or closed.
   */
  defaultOpened: PropTypes.bool,
  /**
   * Defines the callback to be exacuted when clicking the collapsible header.
   */
  onClick: PropTypes.func
};

Collapsible.defaultProps = {
  header: null,
  children: null,
  size: 'small',
  padding: 'all',
  isOpen: null,
  onClick: null,
  defaultOpened: false,
  className: null
};

export default Collapsible;
