import { toastShadow } from '@atlas-design-system/tokens';

const baseOptions = {
  className: 'atls-toast',
  duration: 8000,
  position: 'bottom-center',
  showProgressBar: true,
  // Workaround for styles not being applied to the toast message (harcoded by the library)
  ariaProps: {
    role: 'status',
    'aria-live': 'polite',
    className: 'atls-toast-message'
  },
  style: {
    background: 'blue',
    boxShadow: toastShadow,
    width: '360px',
    maxWidth: '360px',
    color: '#fff',
    fontSize: '16px',
    lineHeight: '1.5rem',
    borderRadius: '0',
    padding: '0',
    alignItems: 'flex-start'
  }
};

export default baseOptions;
