import React from 'react';
import PropTypes from 'prop-types';
import Select from './deprecated/Select';
import NextSelect from './Select';

const SelectProxy = React.forwardRef(({ next, ...props }, ref) => {
  if (next) {
    return <NextSelect {...props} ref={ref} />;
  }
  return <Select {...props} />;
});

SelectProxy.propTypes = {
  next: PropTypes.bool
};

SelectProxy.defaultProps = {
  next: false
};

SelectProxy.displayName = 'SelectProxy';

export default SelectProxy;
