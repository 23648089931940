import React from 'react';
import PropTypes from 'prop-types';
import noop from 'noop';
import clsx from 'clsx';
import onEnter from 'utils/onEnter';
import { profileMenu } from './blocks';

const Option = ({ children, className, onClick, ...otherProps }) => {
  const handleKeyDown = (event) => {
    onEnter(onClick)(event);
  };
  return (
    <li
      className={clsx(profileMenu('option'), className)}
      tabIndex={-1}
      role='menuitem'
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...otherProps}
    >
      {children}
    </li>
  );
};

Option.propTypes = {
  /**
   * Children nodes of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Callback function triggered when component is clicked.
   */
  onClick: PropTypes.func
};

Option.defaultProps = {
  children: null,
  className: null,
  onClick: noop
};

Option.displayName = 'ProfileMenu.Option';

export default Option;
