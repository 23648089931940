import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const IconBase = React.forwardRef(({ Icon, className, ...restProps }, ref) => (
  <Icon className={clsx(['atls-icon', className])} {...restProps} ref={ref} />
));

IconBase.displayName = 'IconBase';

IconBase.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
  className: PropTypes.string
};

IconBase.defaultProps = {
  className: null
};

export default IconBase;
