import React from 'react';
import Message from '../Message';

const parseMessage = (message) => {
  if (typeof message === 'string') {
    return <Message heading={message} />;
  }
  return <Message heading={message.heading} content={message.content} />;
};

export default parseMessage;
