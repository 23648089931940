import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import noop from 'noop';
import { profileMenu } from './blocks';

const SignOut = ({ children, className, onClick, ...otherProps }) => (
  <button
    className={clsx(profileMenu('signout'), className)}
    appearance='tertiary'
    type='button'
    onClick={onClick}
    {...otherProps}
  >
    {children}
  </button>
);

SignOut.propTypes = {
  /**
   * Children nodes of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Callback function triggered when Item is selected.
   */
  onClick: PropTypes.func
};

SignOut.defaultProps = {
  children: null,
  className: null,
  onClick: noop
};

SignOut.displayName = 'ProfileMenu.SignOut';

export default SignOut;
